import httpService from "shared/http/http";

const getUserName = (userName) => {
  const payload = {
    userName: userName,
  };
  const request = {
    name: "fetchByUserName",
  };

  return new Promise((resolve, reject) => {
    httpService.send(request, payload).then(
      (response) => {
        console.log("The fetch by username  is", response);
        if (response && response.message && response.message.effectiveRoles) {
          resolve(response.message);
        } else {
          console.log("Error in fetching user");
        }
      },
      (err) => {
        // resetLoader(loginButton, setLoginButton);
        console.log("Error in fetching user", err);
      }
    );
  });

};

export const getSessionKey = () => {
  const request = {
    name: "getSessionKey",
  };
  return httpService.send(request);
};

const loginHttpService = {
  getUserName,
};
export default loginHttpService;

import { useCallback, useEffect, useState } from "react";

export const useCustomModal = (shouldDisableScroll) => {
  const [isShowing, setIsShowing] = useState(false);

  const toggle = useCallback(() => {
    setIsShowing((isShowing) => !isShowing);
  }, []);

  useEffect(() => {
    if (isShowing && shouldDisableScroll) {
      document.body.setAttribute("style", "overflow: hidden");
    } else {
      document.body.setAttribute("style", "");
    }
  }, [isShowing, shouldDisableScroll]);

  return {
    isShowing,
    toggle,
  };
};

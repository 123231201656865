import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CancelRoundedIcon from "@material-ui/icons/Cancel";
import DavintaLogo from "assets/images/davinta-logo.svg";
import DavintaWhiteLogo from "assets/images/davinta_white_logo.svg";
import darkHelpIcon from "assets/images/header/dark_help_icon.svg";
import supportImage from "assets/images/header/flipkart_support-phone.svg";
import detailSupport from "assets/images/header/help_icon.svg";
import interimDetailSupport from "assets/images/header/interim_help_icon.svg";
import callIcon from "assets/images/registration/flipkart-call-image.svg";
import Modal from "components/Dfs-popup/customize-popup/CustomModal";
import { useCustomModal } from "components/Dfs-popup/customize-popup/useCustomModal";
import DfsButton from "components/dfs-button/DfsButton";
import { WhatsappAssistButton } from "containers/partner-module/partner-container/prospect-assist/whatsapp-assist/WhatsappAssistButton";
import { getRouteConfig } from "containers/partner-module/partner-container/prospect-details-interim/utils/route.config";
import partnerHttpService, {
  postForm,
} from "containers/partner-module/partner.http.service";
import {
  OnboardingFlowTypeContext,
  ReferralInfoContext,
  ThemeModeInfoContext,
  UserInfoContext,
} from "contexts/user.context";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { popupService } from "shared/confirmation-popup/popup.service";
import {
  ANCHOR_URLS,
  ONBOARDING_FLOW_TYPES,
  THEME_MODE,
  support_number,
  whatsappSupportNo,
} from "shared/utils/Constants/constant";
import { useGlobalStore } from "store/global-store/global-store";
import classes from "./header.module.css";

const FAQ_HELP_PAGES = [
  "prospects/personalInfo",
  "prospects/personalDocuments",
  "prospects/personalAddress",
  "prospects/businessInfo",
  "prospects/businessDocuments",
  "prospects/bankInfo",
  "prospects/coapplicants/list",
  "prospects/coapplicants/info",
  "prospects/coapplicants/docs",
  "prospects/coapplicants/address",
  "prospects/submit",
  "faq",
  "partner/kyc",
  "agreement",
];

const EXIT_HELP_PAGES = ["consent"];

const SHOW_BACK_BUTTON_PAGES = [
  "prospects/personalInfo",
  "prospects/personalDocuments",
  "prospects/personalAddress",
  "prospects/businessInfo",
  "prospects/businessDocuments",
  "prospects/bankInfo",
  "prospects/coapplicants/list",
  "prospects/coapplicants/info",
  "prospects/coapplicants/docs",
  "prospects/coapplicants/address",
  "prospects/vkyc",
  "prospects/agreement",
  "prospects/submit",
  "faq",
  "flipkart",
  "flipkart/contact-information",
  "flipkart/faqs",
  "supply-chain",
  "supply-chain/contact-information",
  "supply-chain/faqs",
  "ninjacart",
  "ninjacart/contact-information",
  "ninjacart/faqs",
  "solv",
  "solv/contact-information",
  "solv/faqs",
  "online-anchors",
  "online-anchors/contact-information",
  "online-anchors/faqs",
];
const HELP_TYPE = {
  support: "support",
  faq: "faq",
  exit: "exit",
};

const HeaderComponent = React.forwardRef((props, headerRef) => {
  const [isLoggedIn] = useState(false);
  const [showBackButton, setShowBackButton] = useState(true);
  const [helpType, setHelpType] = useState(HELP_TYPE.faq);
  const { isShowing, toggle } = useCustomModal(true);
  const [modalConfig, setModalConfig] = useState(undefined);
  const { onboardingType, loanStatus } = useContext(UserInfoContext);
  const location = useLocation();
  const history = useHistory();
  const [globalState] = useGlobalStore();
  const { flowType } = useContext(OnboardingFlowTypeContext);
  const { themeMode } = useContext(ThemeModeInfoContext);
  const { referralInfo } = useContext(ReferralInfoContext);

  const isInterimFlow = flowType === ONBOARDING_FLOW_TYPES.INTERIM;
  const isFlipkartFlow = flowType === ONBOARDING_FLOW_TYPES.FK;
  const isDarkMode = themeMode === THEME_MODE.DARK;

  const hostName = document.location.hostname;
  const parts = location.pathname && location.pathname.split("/");

  const isSupportPage = ANCHOR_URLS.includes(parts[1]);

  useEffect(() => {
    if (FAQ_HELP_PAGES.some((page) => location.pathname.includes(page))) {
      setHelpType(HELP_TYPE.faq);
    } else if (
      EXIT_HELP_PAGES.some((page) => location.pathname.includes(page))
    ) {
      setHelpType(HELP_TYPE.exit);
    } else {
      setHelpType(HELP_TYPE.support);
    }

    if (
      SHOW_BACK_BUTTON_PAGES.some((page) => location.pathname.includes(page))
    ) {
      setShowBackButton(true);
    } else {
      setShowBackButton(false);
    }
  }, [location.pathname]);

  const getBackButton = () => {
    let display = null;
    if (showBackButton) {
      display = (
        <div
          className="flex items-center cursor-pointer"
          id="back-button"
          onClick={() => {
            if (globalState.showConfirmationPopup) {
              popupService.showLeaveConfirmation(history.goBack, []);
            } else {
              const config = getRouteConfig(
                referralInfo.apolloOnboardingFlow,
                onboardingType,
                loanStatus && loanStatus.eKycCompleted,
                loanStatus && loanStatus.lenderName
              );
              const path = parts.slice(-1);

              if (config && config[path] && config[path].onBackRedirectTo) {
                history.replace(config[path].onBackRedirectTo);
              } else {
                history.goBack();
              }
            }
          }}
        >
          {isInterimFlow || isSupportPage ? (
            <ArrowBackIcon
              style={
                isDarkMode && !isSupportPage
                  ? { color: "white" }
                  : { color: "#6B100E" }
              }
              id="back-button-icon"
              onClick={() => {
                // Please don't remove the console as the service side will know when the user exits the support page.
                isSupportPage &&
                  parts.length === 2 &&
                  console.log("davinta_exit");
              }}
            />
          ) : (
            <React.Fragment>
              <ArrowBackIosIcon style={{ color: "white" }} />
              <h2 className={classes.back_text + " font-semibold"}>Back</h2>
            </React.Fragment>
          )}
        </div>
      );
    }
    return display;
  };
  const helpClicked = () => {
    setModalConfig(null);
    if (helpType === HELP_TYPE.faq) {
      if (globalState.showConfirmationPopup) {
        popupService.showLeaveConfirmation(history.push, ["/partner/faq"]);
      } else {
        history.push("/partner/faq");
      }
    } else if (helpType === HELP_TYPE.support) {
      toggle();
    } else if (helpType === HELP_TYPE.exit) {
      const handleExit = () => {
        if (!referralInfo.redirectConfirmUrl) {
          postForm(`${window.location.origin}/manage/#/login`);
        } else {
          redirectParent();
        }
      };
      popupService.showExitConfirmation(handleExit);
    }
  };
  const getRightContent = () => {
    let display = (
      <div className="flex justify-center items-center">
        {/* {closeButtonUi()} */}
      </div>
    );
    const helpNotRequiredPaths = [
      "eligibility",
      "faq",
      "notFound",
      "conclusion",
      "assisted",
      "register",
      "partner",
      "agreement",
      "businessDocs",
      "consent",
      "terms",
    ];

    if (hostName.includes("support")) {
      display = (
        <WhatsappAssistButton type="icon" supportNo={whatsappSupportNo} />
      );
    } else if (
      !isLoggedIn &&
      !helpNotRequiredPaths.includes(parts[parts.length - 1])
    ) {
      let supportIcon = supportImage;
      let helpClass = classes.support_text;
      let helpText = "Help";
      if (isDarkMode && !isFlipkartFlow) {
        helpClass = classes.back_text;
        supportIcon = darkHelpIcon;
      } else if (isInterimFlow) {
        supportIcon = interimDetailSupport;
        helpText = "Get help";
      } else if (showBackButton) {
        helpClass = classes.back_text;
        supportIcon = detailSupport;
      }

      display = (
        <div className="flex justify-center items-center">
          <div
            id="help-button"
            onClick={helpClicked}
            className="flex justify-center items-center cursor-pointer"
          >
            <img
              className={classes.helpImage + "mr-2"}
              src={supportIcon}
              alt="help"
            />
            <h2 className={helpClass + " font-semibold ml-1"}>{helpText}</h2>
          </div>
          {/* {closeButtonUi()} */}
        </div>
      );
    } else if (EXIT_HELP_PAGES.includes(parts[parts.length - 1])) {
      display = (
        <div
          id="exit-button-icon"
          onClick={helpClicked}
          className={"flex justify-center items-center cursor-pointer"}
        >
          <CancelRoundedIcon
            color={"primary"}
            className={classes.closeButton + " ml-3"}
          />
          <h2 className={classes.support_text + " font-semibold ml-1 pt-1"}>
            {"Exit"}
          </h2>
        </div>
      );
    }
    return display;
  };

  const redirectParent = async () => {
    await partnerHttpService.redirectFlipkart().catch((err) => {
      console.log(err);
    });
  };

  // const closeButtonUi = () => {
  //   return <React.Fragment>
  //     <CancelRoundedIcon onClick={openModal} color={showBackButton ? "" : 'primary'} className={(showBackButton ? classes.closeButtonOnboardingClass : classes.closeButton) + " ml-3 cursor-pointer"} />
  //   </React.Fragment>
  // }

  const getContent = () => {
    let leftDisplay = (
      <React.Fragment>
        <img
          width="80px"
          src={isDarkMode && !isFlipkartFlow ? DavintaWhiteLogo : DavintaLogo}
          alt="logo"
        />
      </React.Fragment>
    );

    let headerClasses = [classes.header_container];
    if (showBackButton && (isInterimFlow || isSupportPage)) {
      leftDisplay = getBackButton();
    } else if (showBackButton) {
      headerClasses.push(classes.details_header);
      leftDisplay = getBackButton();
    }
    return (
      <div ref={headerRef} className={headerClasses.join(" ")}>
        {leftDisplay}
        {getRightContent()}
      </div>
    );
  };

  // const call = () => {
  //   window.open(`tel:${callNumber}`)
  // }

  // const openModal = () => {
  //   setModalConfig("redirect")
  //   toggle()
  // }

  const renderModelConfig = (modalConfig) => {
    let returnValue;
    if (modalConfig === "redirect") {
      returnValue = returnValue = (
        <React.Fragment>
          <p className={"my-3.5"}>
            {location.pathname.includes("prospects") ||
            location.pathname.includes("register")
              ? "Please make sure to save your application before exiting."
              : ""}
          </p>
          <div className="flex justify-between mb-2">
            <div className="flex-basis-2">
              <DfsButton
                // color="primary"
                buttonId="exit-no"
                variant="outlined"
                fullWidth={true}
                label="No"
                handleClick={toggle}
              ></DfsButton>
            </div>
            <div className="flex-basis-2">
              <DfsButton
                fullWidth={true}
                buttonId="exit-yes"
                label="Yes, exit"
                handleClick={redirectParent}
              ></DfsButton>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      returnValue = (
        <React.Fragment>
          <p className={"my-3.5"}>
            For any help, you can speak to one of our regional support
            executive:
          </p>
          <div className={"flex  my-5"}>
            <img className={classes.phone_image} src={callIcon} alt="call" />
            <h2 className="ml-3">{callNumber}</h2>
          </div>

          {/* <DfsButton label="Call" handleClick={call} fullWidth={true}></DfsButton> */}
        </React.Fragment>
      );
    }

    return returnValue;
  };

  let callNumber = support_number;
  if (loanStatus && loanStatus.assignedUserMobileNo)
    callNumber = loanStatus.assignedUserMobileNo;
  else if (loanStatus && loanStatus.contactUs)
    callNumber = loanStatus.contactUs;

  return (
    <React.Fragment>
      <div className="mb-1">
        {getContent()}
        <Modal
          isShowing={isShowing}
          hide={toggle}
          header={
            modalConfig === "redirect" ? "Do you want to exit?" : "Support"
          }
        >
          {renderModelConfig(modalConfig)}
        </Modal>
      </div>
    </React.Fragment>
  );
});

export default HeaderComponent;

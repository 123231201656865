import { Subject } from "rxjs";
import { UI_CONFIG } from "./confirmation-popup-config";

const subject = new Subject();

export const popupService = {
  showLeaveConfirmation: (callback, args) =>
    subject.next({
      callback: callback,
      callbackArgs: args,
      configurationData: UI_CONFIG.leaveConfirmationPop,
      isShowing: true,
    }),
  showSubmitConfirmation: (callback, args) =>
    subject.next({
      callback: callback,
      callbackArgs: args,
      configurationData: UI_CONFIG.submitConfirmationPop,
      isShowing: true,
    }),
  showExitConfirmation: (callback) =>
    subject.next({
      callback: callback,
      callbackArgs: [],
      configurationData: UI_CONFIG.exitConfirmationPop,
      isShowing: true,
    }),
  showSkipConfirmation: (configurationData, callback) =>
    subject.next({
      callback: callback,
      callbackArgs: [],
      configurationData: configurationData,
      isShowing: true,
    }),
  getPopup: () => subject.asObservable(),
};

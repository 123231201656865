import forge from "node-forge";

var AESCryptoKey;

/**
 * Function to convert ArrayBuffer to Base64 string
 * @param {ArrayBuffer} arrayBuffer
 * @returns string
 */
const arrayBufferToBase64 = (arrayBuffer) => {
  return window.btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
};

/**
 * Function to convert Uint8Array to Base64 string
 *
 * @param {Uint8Array} uint8Array
 * @returns string
 */
const uint8ArrayToBase64 = (uint8Array) => {
  return window.btoa(String.fromCharCode(...uint8Array));
};

/**
 * Function to convert Base64 string to array buffer
 *
 * @param {string} base64
 * @returns Uint8Array
 */
const base64ToArrayBuffer = (base64) => {
  return new Uint8Array(
    window
      .atob(base64)
      .split("")
      .map((char) => char.charCodeAt(0))
  );
};

/**
 * Function for generating an RSA key pair
 *
 * @returns object containing publicKeyPem and privateKey of generated RSA key pair
 */
export const generateRSAKeyPair = () => {
  const keyPair = forge.pki.rsa.generateKeyPair({ bits: 1024, e: 0x10001 });

  return {
    publicKeyPem: window.btoa(forge.pki.publicKeyToPem(keyPair.publicKey)),
    privateKey: keyPair.privateKey,
  };
};

/**
 * Function to encrypt a payload using AES algorithm. The algorithm makes use of a
 * key and IV. Encrypted payload is appended with encoded IV value to form the final
 * encrypted payload.
 *
 * @param {*} payload
 * @returns object containing encryptedPayload and version
 */
export const encryptPayload = async (payload) => {
  const iv = crypto.getRandomValues(new Uint8Array(16));

  let encryptedPayload;
  try {
    encryptedPayload = await window.crypto.subtle.encrypt(
      { name: "AES-CBC", iv: iv },
      AESCryptoKey,
      new TextEncoder().encode(JSON.stringify(payload))
    );
  } catch (err) {
    throw err;
  }

  const payloadWithIv =
    arrayBufferToBase64(encryptedPayload) + "." + uint8ArrayToBase64(iv);

  return {
    encryptedPayload: payloadWithIv,
    version: "v1",
  };
};

/**
 * Function to decrypt the encrypted payload using IV appended to the encrypted string
 * and key using AES algorithm.
 *
 * @param {string} encryptedResponse
 * @returns the decrypted payload as an object
 */
export const decryptPayload = async (encryptedResponse) => {
  const [encryptedPayload, encodedIv] =
    encryptedResponse.split(".");
  let decryptedPayload;

  try {
    decryptedPayload = await window.crypto.subtle.decrypt(
      { name: "AES-CBC", iv: base64ToArrayBuffer(encodedIv) },
      AESCryptoKey,
      base64ToArrayBuffer(encryptedPayload)
    );
    return JSON.parse(new TextDecoder().decode(decryptedPayload));
  } catch (err) {
    if(err.constructor === SyntaxError){
      console.warn(err);
      return new TextDecoder().decode(decryptedPayload);
    }
    throw err;
  }
};

/**
 * Function to decrypt AES key using RSA private key and import and store it as CryptoKey.
 *
 * @param {pki.rsa.PrivateKey} privateKey
 * @param {string} encryptedAesKey
 */
export const setAESKey = async (privateKey, encryptedAesKey) => {
  try {
    const aesKey = privateKey.decrypt(forge.util.decode64(encryptedAesKey));
    AESCryptoKey = await window.crypto.subtle.importKey(
      "raw",
      base64ToArrayBuffer(aesKey),
      "AES-CBC",
      false,
      ["encrypt", "decrypt"]
    );
  } catch (err) {
    throw err;
  }
};

const COLOR_CONST = {
  primary: "#0096FF",
  secondary: "#00529A",
  textSecondary: "#5F7687",
  lightBlue: "#EFF6FF",
  dark: "#2f4580",
  medium: "#7391b5",
  light: "#bfd0e4",
  disbaled: "#f5f7ff",
  background: "#f5f7ff",
  green: "#50B748",
  orange: "#ff7848",
  red: "#f23669",
  white: "#ffffff",
  error: "#FFE0E9",
  warning: "#ffe2d8",
  info: "#f4f6ff",
  success: "#dcf5f3",
  lightBrown: "#ca5d4e",
  darkBrown: "#6b100e",
  yellow: "#FFB033",
  disabled: "#BCCBDD",
  darkGreen: "#336268",
  black: "#1C2026",
  lightBlack: "#4F5A62",
  cyan: "#00DEC5",
  gray: "#B3BAC7",
  darkGray: "#edf3fc",
  popUpBackground: "#1f2c38",
  darkBackGround: "#1E2831",
  darkDisabled: "#617792",
  darkError: "#f44336",
  heading: "#273238",
  borderColor: "#e5e7eb",
  darkBorderColor: "#313c46",
  snackBarBackground: "#ffeaea",
};

export default COLOR_CONST;

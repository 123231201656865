export const compareArray = (a1, a2) => {
  if (a1 === a2) return true;
  if (a1 == null || a2 == null) return false;
  if (a1.length !== a2.length) return false;

  a1.sort();
  a2.sort();

  for (var i = 0; i < a1.length; ++i) {
    if (a1[i] !== a2[i]) return false;
  }

  return true;
};

export const replacePlaceholder = (str, data) => {
  let regIterator = str.matchAll(/\{([^}]+)\}/g);
  let matches = [...regIterator];
  matches.forEach((match) => (str = str.replace(match[0], data[match[1]])));
  return str;
};

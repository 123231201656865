import { IFrameContext } from "contexts/iframe.context";
import { useContext } from "react";

const IFrame = () => {
  const { src, title } = useContext(IFrameContext);

  return <iframe src={src} title={title} width="100%" height="700px" />;
};

export default IFrame;

import { initGlobalStore } from "./global-store";

const ConfigureGlobalStore = (initialState) => {

  const actions = {
    SHOW_CONFIRMATION_POPUP: (state) => {
      return {
        ...state,
        showConfirmationPopup: true
      };
    },

    CLEAR_CONFIRMATION_POPUP: (state) => {
      return {
        ...state,
        showConfirmationPopup: false
      };
    },
  };

  initGlobalStore(actions, initialState);
};

export default ConfigureGlobalStore;

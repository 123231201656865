import httpService from "shared/http/http";
import sessionStorageService from "shared/sessionStorage";
import { snackbarService } from "shared/snackbar/snackbar.service";
import messages from "shared/messages";
import { isIOS } from "./partner-container/prospect-details/utils/platform";

export const postForm = (path, method = "get") => {
  // The rest of this code assumes you are not using a library.
  // It can be made less verbose if you use one.

  const form = document.createElement("form");
  form.method = method;
  form.action = path;

  const url = new URL(path);
  url.searchParams.forEach((value, key) => {
    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = key;
    hiddenField.value = value;
    form.appendChild(hiddenField);
  });

  document.body.appendChild(form);
  form.submit();
};

const redirectFlipkart = () => {
  const referralId = sessionStorageService.getReferralId();
  const request = {
    name: "bpRedirectConfrimUrl",
    params: {
      refId: referralId,
    },
  };

  return new Promise((resolve, reject) => {
    httpService.send(request).then(
      (response) => {
        // const path = `https://kyros.davinta.com/api/partner-referral/bp-redirect/${referralId}`
        postForm(`${response}`);
        resolve(response);
        console.log("bpRedirectCOnfirmUrl", response);
      },
      (err) => {
        snackbarService.show(
          snackbarService.messageType.error,
          err,
          "Cant redirect" //TODO: need to changes
        );
        reject(err);
      }
    );
  });
};

const redirectToDigilocker = () => {
  const prospectId = sessionStorageService.getProspectId();
  // const requestUrl = "/digilocker/redirect?prospect-id=";
  // postForm(`${environmentUrl}${devEnvironment.apiUrl}${requestUrl}${prospectId}`);

  const request = {
    name: "authorizeDigiLocker",
    params: {
      prospectId: prospectId,
    },
  };

  return new Promise((resolve, reject) => {
    httpService.send(request).then(
      (response) => {
        postForm(`${response}`);
        resolve(response);
      },
      (err) => {
        snackbarService.show(
          snackbarService.messageType.error,
          err,
          "Unable to redirect to digilocker"
        );
        console.log(err);
        reject(err);
      }
    );
  });
};

const createProspect = (payload) => {
  const request = {
    name: "createProspect",
  };

  return new Promise((resolve, reject) => {
    httpService.send(request, payload).then(
      (response) => {
        if (response && response.message) {
          resolve(response.message);
        }
      },
      (err) => {
        let error = { ...err };
        let messageShow = "Cant redirect";
        if (err && err.status) {
          const voilation =
            err.status.violations && err.status.violations.length
              ? err.status.violations.find((obj) => obj.field === "panNumber")
              : null;
          const idCheckPan =
            err.status.errorCode &&
            err.status.errorCode === "error.ineligible_pan_card_idencheck_score"
              ? true
              : false;
          const fieldName = voilation && voilation.field;
          if (idCheckPan || fieldName === "panNumber") {
            messageShow = messages.PANCARD_FAILED;
            error = "PAN_CARD_FAILED";
          }
        }
        snackbarService.show(
          snackbarService.messageType.error,
          error,
          messageShow
        );

        reject(error);
      }
    );
  });
};

const getLocationByPostalCode = (postalCode) => {
  let locationRequest = {
    name: "getLocationByPostalCode",
    params: {
      postalCd: postalCode,
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(locationRequest).then(
      (response) => {
        if (response && response.message) {
          resolve(response.message);
        }
      },
      (err) => {
        // snackbarService.show(err, null, "Invalid Pin code");
        reject(err);
      }
    );
  });
};

const getBankDetailsByIfsc = (ifscCode) => {
  let locationRequest = {
    name: "getBankDetailsByIfsc",
    params: {
      ifscCode: ifscCode,
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(locationRequest).then(
      (response) => {
        if (response && response.message) {
          resolve(response.message);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
};

export const eligibilityLoan = () => {
  const prospectId = sessionStorageService.getProspectId();
  let locationRequest = {
    name: "loanEligibility",
    params: {
      propsectId: prospectId,
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(locationRequest).then(
      (response) => {
        if (response && response.message) {
          if (response.message.applicationId) {
            sessionStorageService.setCreditReqId(
              response.message.applicationId
            );
          }
          resolve(response.message);
        }
      },
      (err) => {
        snackbarService.show(
          snackbarService.messageType.error,
          err,
          "Cant redirect" //TODO: need to changes
        );
        reject(err);
      }
    );
  });
};

const updateSourceType = (prospectId, payload) => {
  const userProsePectId = prospectId
    ? prospectId
    : sessionStorageService.getProspectId();
  let locationRequest = {
    name: "updateSourceType",
    params: {
      propsectId: userProsePectId,
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(locationRequest, payload).then(
      (response) => {
        if (response && response.message) {
          resolve(response.message);
        }
      },
      (err) => {
        snackbarService.show(
          snackbarService.messageType.error,
          err,
          "Cant redirect" //TODO: need to changes
        );
        reject(err);
      }
    );
  });
};

const getRefralDetail = (refId) => {
  let request = {
    name: "getPartnerReferral",
    params: {
      refId: refId,
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(request).then(
      (response) => {
        if (response && response.message) {
          resolve(response.message);
        }
      },
      (err) => {
        snackbarService.show(snackbarService.messageType.error, err);
        reject(err);
      }
    );
  });
};

export const getProspect = (prospectId) => {
  const request = {
    name: "getProspect",
    params: {
      prospectId: prospectId,
    },
  };

  return new Promise((resolve, reject) => {
    httpService.send(request).then(
      (response) => {
        if (response && response.message) {
          resolve(response.message);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
};

export const getAllDocs = (prospectId) => {
  const reqObj = {
    name: "getAllDocsByProspectId",
    params: {
      prospectId: prospectId,
    },
  };

  return new Promise((resolve, reject) => {
    httpService.send(reqObj).then(
      (response) => {
        if (response && response.results) {
          resolve(response.results);
        } else {
          console.log("Unable to get documents ");
        }
      },
      (err) => {
        console.log(err);
        reject(err);
      }
    );
  });
};

export const getCoApplicants = () => {
  const reqObj = {
    name: "getCoApplicants",
    params: {
      prospectId: sessionStorageService.getProspectId(),
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(reqObj).then(
      (response) => {
        if (response && response.results) {
          resolve(response.results);
        }
      },
      (err) => {
        console.log(err);
        reject(err);
      }
    );
  });
};

const processDigiLockerFiles = (code) => {
  const prospectId = sessionStorageService.getProspectId();

  let processDigiLockerFilesRequest = {
    name: "processDigiLockerFiles",
    params: {
      prospectId: prospectId,
      code: code,
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(processDigiLockerFilesRequest).then(
      (response) => {
        resolve(response.message);
      },
      (err) => {
        reject(err);
      }
    );
  });
};

export const completeLoanAgreement = () => {
  const reqObj = {
    name: "completeLoanAgreement",
    params: {
      prospectId: sessionStorageService.getProspectId(),
      loanApplicationId: sessionStorageService.getCreditRequirmentId(),
      signatureStatus: true,
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(reqObj).then(
      (response) => {
        resolve(true);
      },
      (err) => {
        console.log(err);
        reject(err);
      }
    );
  });
};

export const downloadLoanAgreement = () => {
  let prospectId = sessionStorageService.getProspectId();
  let creditReqId = sessionStorageService.getCreditRequirmentId();
  let reqObj = {
    name: "downloadLoanAgreement",
    params: {
      creditReqId: creditReqId,
      prospectId: prospectId,
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(reqObj).then(
      (response) => {
        window.open(response);
      },
      (err) => reject(err)
    );
  });
};

export const downloadSignedLoanAgreement = (docId) => {
  let reqObj = {
    name: "downloadDocument",
    params: {
      documentId: docId,
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(reqObj).then(
      (response) => {
        window.open(response);
        // saveFile(response, response.type, "Loan agreement"); TODO:old method is this one
        // const url = window.URL.createObjectURL(new Blob([response]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", `LoanAgreement.pdf`);

        // Append to html link element page
        // document.body.appendChild(link);

        // // Start download
        // link.click();

        // // Clean up and remove the link
        // link.parentNode.removeChild(link);
      },
      (err) => reject(err)
    );
  });
};

export const downloadSanctionLetter = () => {
  let prospectId = sessionStorageService.getProspectId();
  let creditReqId = sessionStorageService.getCreditRequirmentId();
  let reqObj = {
    name: "downloadSanctionLetter",
    params: {
      creditReqId: creditReqId,
      prospectId: prospectId,
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(reqObj).then(
      (response) => {
        // debugger;
        // saveFile(response, "application/pdf", "Sanction letter"); //TODO:old method this one new method is testing
        // const url = window.URL.createObjectURL(new Blob([response]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", `SanctionLetter.pdf`);
        window.open(response);
        // Append to html link element page
        // document.body.appendChild(link);

        // Start download
        // link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
      },
      (err) => reject(err)
    );
  });
};

const getAllOffers = () => {
  let offersRequest = {
    name: "findAllOffers",
  };
  return new Promise((resolve, reject) => {
    httpService.send(offersRequest).then(
      (response) => {
        if (response && response.results) {
          resolve(response.results);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
};

const getAllOnboardingTypeConfigurations = () => {
  let configRequest = {
    name: "getAllConfigurations",
    params: {
      groupCd: "ST_APOLLO",
    },
  };

  return new Promise((resolve, reject) => {
    httpService.send(configRequest).then(
      (response) => {
        if (response && response.results) {
          resolve(response.results);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
};

export const whatsappOptIn = (payload) => {
  const request = {
    name: "optUserRequest",
  };

  return new Promise((resolve, reject) => {
    httpService.send(request, payload).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
};

export const initiateOkycVkyc = () => {
  const reqObj = {
    name: "initiateOkycVkyc",
    params: {
      prospectId: sessionStorageService.getProspectId(),
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(reqObj).then(
      (response) => {
        if (response && response.message) {
          resolve(response.message);
        } else {
          reject();
        }
      },
      (err) => {
        console.log(err);
        reject(err);
      }
    );
  });
};

export const getLoanAgreementDetails = (customerId, loanApplicationId) => {
  const request = {
    name: "getLoanAgreementDetails",
    params: {
      customerId: customerId,
      loanApplicationId: loanApplicationId,
    },
  };

  return new Promise((resolve, reject) => {
    httpService.send(request).then(
      (response) => {
        if (response && response.signerInfo) {
          resolve(response.signerInfo);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
};

export const getDigitapStatusLite = (prospectId, applicationId) => {
  const reqObj = {
    name: "getDigitapStatusLite",
    params: {
      prospectId: prospectId,
      applicationId: applicationId,
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(reqObj).then(
      (response) => {
        if (response && response.message) {
          resolve(response.message);
        } else {
          reject();
        }
      },
      (err) => {
        console.log(err);
        reject(err);
      }
    );
  });
};

export const fetchEsignStatus = (prospectId, applicationId) => {
  const reqObj = {
    name: "fetchEsignStatus",
    params: {
      prospectId: prospectId,
      applicationId: applicationId,
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(reqObj).then(
      (response) => {
        if (response && response.message) {
          resolve(response.message);
        } else {
          reject();
        }
      },
      (err) => {
        console.log(err);
        reject(err);
      }
    );
  });
};

export const updateConsent = (consentChecked) => {
  let payload = {
    agreed: consentChecked,
    consentType: {
      codeName: "INFORMATION_SHARING_CONSENT",
    },
  };
  const reqObj = {
    name: "updateConsent",
    params: {
      refId: sessionStorageService.getReferralId(),
    },
  };
  return new Promise((resolve, reject) => {
    httpService.send(reqObj, payload).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
};

export const getApplicationConfiguration = async () => {
  const prospectId = sessionStorageService.getProspectId();
  let applicationConfigurationRequest = {
    name: "getApplicationConfiguration",
    params: {
      propsectId: prospectId,
      deviceOs: isIOS() ? "IOS" : "ANDROID",
    },
  };

  try {
    const response = await httpService.send(applicationConfigurationRequest);
    if (response?.message) {
      return response.message;
    }
  } catch (err) {
    throw err;
  }
};

export const getCustomer = async (customerId) => {
  const request = {
    name: "getAllCustomerDetailsById",
    params: {
      customerId: customerId,
    },
  };

  const response = await httpService.send(request);
  return response.message;
};

export const getMandateRegistration = async (cifId) => {
  const request = {
    name: "findByCifId",
    params: {
      cifId: cifId,
    },
  };

  const response = await httpService.send(request);
  return response.message;
};

export const redirectWithTokenSwitch = async (customerId, redirectType) => {
  const request = {
    name: "redirectWithTokenSwitch",
    params: {
      customerId: customerId,
      redirectType: redirectType,
    },
  };

  try {
    httpService.send(request);
  } catch (err) {
    snackbarService.show(
      snackbarService.messageType.error,
      err,
      messages.REDIRECT_FAILED
    );
  }
};

const partnerHttpService = {
  getLocationByPostalCode,
  createProspect,
  eligibilityLoan,
  updateSourceType,
  getRefralDetail,
  redirectFlipkart,
  getBankDetailsByIfsc,
  redirectToDigilocker,
  processDigiLockerFiles,
  completeLoanAgreement,
  downloadLoanAgreement,
  downloadSignedLoanAgreement,
  downloadSanctionLetter,
  getAllOffers,
  getAllOnboardingTypeConfigurations,
};
export default partnerHttpService;

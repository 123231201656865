import { createMuiTheme } from "@material-ui/core/styles";
import { ONBOARDING_FLOW_TYPES } from "shared/utils/Constants/constant";
import color from "./color-const";
import { mobileView } from "./media.query";

const flipkartTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.primary,
    },
    secondary: {
      main: color.secondary,
    },
    action: {
      disabledBackground: color.disabled,
      disabled: color.medium,
    },
    text: {
      secondary: color.textSecondary,
    },
  },
  typography: {
    fontFamily: "roboto-regular",
    body1: {
      fontSize: 14,
    },
    body2: {
      fontFamily: "Comfortaa",
    },
    button: {
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  overrides: {
    MuiDialog: {
      scrollPaper: {
        // alignItems: mobileView ? "flex-end" : "center",
      },
      paper: {
        // margin: mobileView ? "opx" : "32px",
        // marginBottom: mobileView ? "0px" : "32px",
      },
    },
    MuiGrid: {
      container: { justifyContent: "center" }, // getMobileVIewGrid(),
    },
    MuiButton: {
      root: {
        // padding: "20px",
        minWidth: buttonMinWidth(),
        fontFamily: "roboto-regular",
        color: color.white,
        borderRadius: "3rem",
        fontSize: "14px",
        fontWeight: "600",
        maxWidth: "590px", // just making the web also ase mobile responsive
      },
      containedPrimary: {
        color: color.white,
        "&$disabled": {
          color: color.disbaled,
          background: "#E3EAF1 0% 0% no-repeat padding-box;",
        },
      },
      containedSecondary: {
        color: color.white,
        "&$disabled": {
          color: color.disbaled,
          background: "#E3EAF1 0% 0% no-repeat padding-box;",
        },
      },
      outlinedPrimary: {
        background: color.white + " 0% 0% no-repeat padding-box;",
        color: color.primary,
        border: "1px solid " + color.primary,

        fontWeight: "300",
        opacity: 1,
      },
      outlinedSecondary: {
        background: color.white + " 0% 0% no-repeat padding-box;",
        color: color.secondary,
        border: "1px solid " + color.secondary,

        opacity: 1,
      },
      outlinedSizeSmall: {
        width: "10%",
        height: "42px",
      },
    },
    MuiInputBase: {
      root: {
        minWidth: "300px",
        minHeight: "23px",
        fontSize: "16px",
      },
      input: {
        "-webkit-input-placeholder": {},
      },
    },
    MuiInput: {
      underline: {
        "&&&:before": {
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "10.5px 14px",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "14px",
      },
      shrink: {
        fontSize: "16px",
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#757372",
        },
      },
    },
    MuiPaper: {
      root: {
        color: color.dark,
        minHeight: 33,
      },
      elevation1: {
        boxShadow: "none",
      },
    },

    MuiButtonBase: {
      root: {
        padding: "16px",
        "&$active": {
          borderBottom: "1px solid red",
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      label: {
        color: color.dark,
      },
    },
    MuiMenuItem: {
      root: {
        color: color.dark,
        fontSize: "14px",
      },
    },
    checked: {
      color: "red",
    },
    MuiSelect: {
      icon: {
        color: color.primary,
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: color.lightBlue,
      },
      barColorPrimary: {
        backgroundColor: color.green,
      },
      root: {
        height: 8,
      },
    },
    MuiFormHelperText: {
      root: {
        "&$disabled": {
          color: color.gray,
        },
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: color.lightBlue,
        padding: "20px",
      },
    },
    MuiStepLabel: {
      label: {
        "&$alternativeLabel": {
          marginTop: 8,
          width: "max-content",
        },
      },
    },
    MuiStepButton: {
      root: {
        padding: 0,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: color.textSecondary,
      },
    },
  },
});

const interimTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.darkBrown,
    },
    secondary: {
      main: color.darkGreen,
    },
    action: {
      disabledBackground: color.disabled,
      disabled: color.medium,
    },
    text: {
      secondary: color.textSecondary,
    },
  },
  typography: {
    fontFamily: "roboto-regular",
    body1: {
      fontSize: 14,
    },
    body2: {
      fontFamily: "Comfortaa",
    },
    button: {
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  overrides: {
    MuiGrid: {
      container: { justifyContent: "center" },
    },
    MuiButton: {
      root: {
        minWidth: buttonMinWidth(),
        fontFamily: "roboto-regular",
        color: color.white,
        borderRadius: "3rem",
        fontSize: "14px",
        fontWeight: "600",
        maxWidth: "590px",
      },
      containedPrimary: {
        color: color.white,
        "&$disabled": {
          color: color.disbaled,
          background: "#E3EAF1 0% 0% no-repeat padding-box;",
        },
      },
      containedSecondary: {
        color: color.white,
        "&$disabled": {
          color: color.disbaled,
          background: "#E3EAF1 0% 0% no-repeat padding-box;",
        },
      },
      outlinedPrimary: {
        background: color.white + " 0% 0% no-repeat padding-box;",
        color: color.primary,
        border: "1px solid " + color.primary,

        fontWeight: "300",
        opacity: 1,
      },
      outlinedSecondary: {
        background: color.white + " 0% 0% no-repeat padding-box;",
        color: color.darkGreen,
        border: "1px solid " + color.darkGreen,

        opacity: 1,
      },
      outlinedSizeSmall: {
        width: "10%",
        height: "42px",
      },
    },
    MuiInputBase: {
      root: {
        minWidth: "300px",
        minHeight: "23px",
        fontSize: "16px",
      },
      input: {
        "-webkit-input-placeholder": {},
      },
    },
    MuiInput: {
      underline: {
        "&&&:before": {
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "10.5px 14px",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "14px",
      },
      shrink: {
        fontSize: "16px",
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#757372",
        },
      },
    },
    MuiPaper: {
      root: {
        color: color.black,
        minHeight: 33,
      },
      elevation1: {
        boxShadow: "none",
      },
    },

    MuiButtonBase: {
      root: {
        padding: "16px",
        "&$active": {
          borderBottom: "1px solid red",
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      label: {
        color: color.dark,
      },
    },
    MuiMenuItem: {
      root: {
        color: color.textSecondary,
        fontSize: "14px",
      },
    },
    checked: {
      color: "red",
    },
    MuiSelect: {
      icon: {
        color: color.darkBrown,
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: color.lightBlue,
      },
      barColorPrimary: {
        backgroundColor: color.yellow,
      },
      root: {
        height: 8,
      },
    },
    MuiRadio: {
      colorPrimary: {
        "&$checked": {
          color: color.darkBrown,
        },
      },
      colorSecondary: {
        "&$checked": {
          color: color.yellow,
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        "&$checked": {
          color: color.yellow,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        "&$disabled": {
          color: color.gray,
        },
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: color.lightBlue,
        padding: "20px",
      },
    },
    MuiStepLabel: {
      label: {
        "&$alternativeLabel": {
          marginTop: 8,
          width: "max-content",
        },
      },
    },
    MuiStepButton: {
      root: {
        padding: 0,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: color.textSecondary,
      },
    },
  },
});

const darkTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.cyan,
    },
    secondary: {
      main: color.cyan,
    },
    action: {
      disabledBackground: color.disabled,
      disabled: color.darkDisabled,
    },
    text: {
      secondary: color.gray,
    },
  },
  typography: {
    fontFamily: "roboto-regular",
    body1: {
      fontSize: 14,
    },
    body2: {
      fontFamily: "Comfortaa",
    },
    button: {
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  overrides: {
    MuiGrid: {
      container: { justifyContent: "center" },
    },
    MuiButton: {
      root: {
        minWidth: buttonMinWidth(),
        fontFamily: "roboto-regular",
        color: color.black,
        borderRadius: "3rem",
        fontSize: "14px",
        fontWeight: "600",
        maxWidth: "590px",
      },
      containedPrimary: {
        color: color.black,
        "&$disabled": {
          color: color.black,
          background: color.lightBlack + " 0% 0% no-repeat padding-box;",
        },
      },
      containedSecondary: {
        color: color.black,
        "&$disabled": {
          color: color.black,
          background: color.lightBlack + " 0% 0% no-repeat padding-box;",
        },
      },
      outlinedPrimary: {
        background: color.black + " 0% 0% no-repeat padding-box;",
        color: color.cyan,
        border: "1px solid " + color.cyan,
        fontWeight: "300",
        opacity: 1,
        "&$disabled": {
          color: color.darkDisabled,
          border: "1px solid " + color.darkDisabled,
        },
      },
      outlinedSecondary: {
        background: color.black + " 0% 0% no-repeat padding-box;",
        color: color.cyan,
        border: "1px solid " + color.cyan,
        opacity: 1,
        "&$disabled": {
          color: color.darkDisabled,
          border: "1px solid " + color.darkDisabled,
        },
      },
      outlinedSizeSmall: {
        width: "10%",
        height: "42px",
      },
    },
    MuiInputBase: {
      root: {
        minWidth: "300px",
        minHeight: "23px",
        fontSize: "16px",
        color: color.darkGray,
      },
      input: {
        "-webkit-input-placeholder": {},
      },
    },
    MuiInput: {
      underline: {
        "&&&:before": {
          borderBottom: "1px solid" + color.gray,
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&$disabled": {
          color: color.gray,
          backgroundColor: color.lightBlack,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "10.5px 14px",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "14px",
      },
      shrink: {
        fontSize: "16px",
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#757372",
        },
        "&$disabled": {
          color: color.gray,
        },
      },
    },
    MuiPaper: {
      root: {
        minHeight: 33,
        color: color.gray,
        backgroundColor: color.popUpBackground,
      },
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiButtonBase: {
      root: {
        padding: "16px",
        "&$active": {
          borderBottom: "1px solid red",
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      label: {
        color: color.darkGray,
        "&$disabled": {
          color: color.gray,
        },
      },
    },
    MuiMenuItem: {
      root: {
        color: color.gray,
        fontSize: "14px",
      },
    },
    checked: {
      color: "red",
    },
    MuiSelect: {
      icon: {
        color: color.cyan,
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: color.lightBlue,
      },
      barColorPrimary: {
        backgroundColor: color.yellow,
      },
      root: {
        height: 8,
      },
    },
    MuiRadio: {
      colorPrimary: {
        "&$disabled": {
          color: color.darkDisabled,
        },
      },
      colorSecondary: {
        "&$disabled": {
          color: color.darkDisabled,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        "&$disabled": {
          color: color.gray,
        },
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: color.darkBackGround,
        padding: "20px",
      },
    },
    MuiStepLabel: {
      label: {
        "&$alternativeLabel": {
          marginTop: 8,
          width: "max-content",
        },
      },
    },
    MuiStepButton: {
      root: {
        padding: 0,
      },
    },
    MuiSwitch: {
      label: {
        fontSize: "76px",
        color: color.gray,
      },
      track: {
        backgroundColor: color.darkDisabled,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: color.lightBlack,
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: color.popUpBackground,
        color: color.darkGray,
        "&:hover": { color: color.cyan },
      },
      dayLabel: { color: color.darkGray },
    },
    MuiPickersSlideTransition: {
      transitionContainer: { "&>*": { color: color.darkGray } },
    },
    MuiPickersToolbarText: { toolbarTxt: { color: "#001d19" } },
    MuiPickersDay: {
      day: { color: color.gray },
      dayDisabled: { color: color.darkDisabled },
    },
  },
});

function buttonMinWidth() {
  const pixel = mobileView ? "130px" : "150px";
  return pixel;
}

export const ONBOARDING_THEME = {
  [ONBOARDING_FLOW_TYPES.FK]: { light: flipkartTheme, dark: flipkartTheme },
  [ONBOARDING_FLOW_TYPES.INTERIM]: { light: interimTheme, dark: darkTheme },
};

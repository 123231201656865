import valueFormatter from "shared/flagResolver";
import { get } from "lodash";
export const transformDisplayData = (json, column) => {
 let returnValue
 if(json && column) {
  let sufixValue = column.dynamicSuffix ? get(json, column.dynamicSuffix) : "";
  if (column.extractValue) {
    returnValue = patterns[column.extractValue](json, column);
  } else {
    let value = deriveComplexKey(json, column);
    returnValue = column.formater
      ? valueFormatter.flagResolver(column.formater, value) + " " + sufixValue
      : value + " " + sufixValue;
  }
 }

  return returnValue?returnValue:"-"
};

export const deriveComplexKey = (json, column) => {
  if (column.key) {
    const key = column.key;
    let prop;
    const props = key.split(".");
    const iLen = props.length;
    let mRow = json;
    for (let i = 0; i < iLen; i++) {
      prop = props[i];
      let candidate = mRow[prop];
      if (candidate) {
        mRow = candidate;
      } else if (candidate === 0) {
        mRow = "0";
      } else {
        break;
      }
    }
    if(typeof mRow ==="object") {
      mRow ="---"
    }
    return mRow;
  } else {
    return "---";
  }
};

const patterns = {
  getPermanentAddressLine: (json, column) => {
    if (json && json.addresses) {
      let item = json.addresses.find(
        (address) =>
          address.addressType &&
          address.addressType.codeName === "ADDRESS_TYPE_PERMANENT"
      );
      if (item) {
        return item.addressLine1;
      }
    }
    return "";
  },
  getCifId: (json, column) => {
    if (json && json.assignedAnchors && json.assignedAnchors.length) {
      return json.assignedAnchors[0];
    }
    return "";
  },
};

import { createContext, useState } from "react";

export const IFrameContext = createContext({
  src: null,
  setSrc: () => {},
  title: null,
  setTitle: () => {},
});

export const IFrameContextProvider = (props) => {
  const [src, setSrc] = useState();
  const [title, setTitle] = useState();

  return (
    <IFrameContext.Provider value={{ src, setSrc, title, setTitle }}>
      {props.children}
    </IFrameContext.Provider>
  );
};

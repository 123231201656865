let storageService = {};
storageService.setAccessToken = (accessToken) => {
  localStorage.setItem("accessToken", accessToken);
};

storageService.updateGenricServerTime = (date) => {
  localStorage.setItem("genricServerDate", date);
};
storageService.getGenricServerTime = () => {
  return localStorage.getItem("genricServerDate");
};
storageService.updateGenricUiTime = (date) => {
  localStorage.setItem("genricUpdatedDate", date);
};
storageService.getGenricUiTime = () => {
  return localStorage.getItem("genricUpdatedDate");
};
storageService.setLoggedInUserDetail = (detail) => {
  localStorage.setItem("LogedInUserDetail", JSON.stringify(detail));
};
storageService.getLoggedInUserDetail = () => {
  return JSON.parse(localStorage.getItem("LogedInUserDetail"));
};

storageService.removeLoggedInUserDetail = () => {
  return localStorage.removeItem("LogedInUserDetail");
};

storageService.getAccessToken = () => {
  return localStorage.getItem("accessToken");
};

storageService.setRefreshToken = (refreshToken) => {
  localStorage.setItem("refreshToken", refreshToken);
};

storageService.getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

storageService.setGenericCodes = (genericList) => {
  localStorage.setItem("genericList", genericList);
};

storageService.getGenericCodes = () => {
  return JSON.parse(localStorage.getItem("genericList"));
};

storageService.removeAccessToken = () => {
  return localStorage.removeItem("accessToken");
};

storageService.removeRefreshToken = () => {
  return localStorage.removeItem("refreshToken");
};

storageService.setProspectId = (prospectId) => {
  localStorage.setItem("prospectId", prospectId);
};

storageService.getProspectId = () => {
  return localStorage.getItem("prospectId");
};

storageService.setActiveProspectId = (prospectId) => {
  localStorage.setItem("activeProspectId", prospectId);
};

storageService.getActiveProspectId = () => {
  return localStorage.getItem("activeProspectId");
};

storageService.setViewMode = (flag) => {
  localStorage.setItem("viewMode", flag);
};

storageService.getViewMode = () => {
  return localStorage.getItem("viewMode");
};

storageService.getEditMode = () => {
  return localStorage.getItem("editMode");
};

storageService.getIsViewMode = () => {
  let item = localStorage.getItem("isViewMode");
  return item === "true";
};

storageService.setIsViewMode = (flag) => {
  return localStorage.setItem("isViewMode", flag);
};

storageService.getIsEndUser = () => {
  let item = localStorage.getItem("isEndUser");
  return item === "true";
};

storageService.setIsEndUser = (flag) => {
  return localStorage.setItem("isEndUser", flag);
};

storageService.setIsEncyptionDisable = () => {
  return localStorage.setItem("disableEncryption", false);
};

storageService.getIsEncyptionDisable = () => {
  return localStorage.getItem("disableEncryption");
};

export default storageService;

import whatsappIcon from "assets/images/whatsapp.svg";
import whatsappOutlineIcon from "assets/images/whatsapp_icon.svg";
import DfsButton from "components/dfs-button/DfsButton";
import PropTypes from "prop-types";
import { OnboardingFlowTypeContext } from "contexts/user.context";
import { useContext } from "react";
import { mobileView } from "shared/theme/media.query";
import { ONBOARDING_FLOW_TYPES } from "shared/utils/Constants/constant";
import classes from "./WhatsappAssistButton.module.scss";

export const WhatsappAssistButton = (props) => {
  const { flowType } = useContext(OnboardingFlowTypeContext);
  const MESSAGE = window.encodeURIComponent(props.message ? props.message : "");
  const WEB_URL = `https://web.whatsapp.com/send?phone=${props.supportNo}&text=${MESSAGE}`;
  const MOB_URL = `https://wa.me/91${props.supportNo}?text=${MESSAGE}`;

  if (props.supportNo) {
    return (
      <a
        href={mobileView ? MOB_URL : WEB_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.type === "icon" ? (
          <div className="flex content-center cursor-pointer">
            <img
              className="h-6 mr-1"
              src={whatsappOutlineIcon}
              alt="whatsapp"
            />
            <h2 className={classes.support_text + " font-semibold pt-1"}>
              Chat with us
            </h2>
          </div>
        ) : (
          <DfsButton
            buttonId="whatsapp-assist"
            label={
              <div className="flex content-center">
                <img className="h-6 mr-1" src={whatsappIcon} alt="whatsapp" />
                <p className={"leading-7 text-sm " + classes.label}>
                  Assist me through WhatsApp
                </p>
              </div>
            }
            variant="outlined"
            fullWidth
            color={
              flowType === ONBOARDING_FLOW_TYPES.INTERIM
                ? "secondary"
                : "primary"
            }
          />
        )}
      </a>
    );
  } else {
    return null;
  }
};

WhatsappAssistButton.propTypes = {
  supportNo: PropTypes.string.isRequired,
  message: PropTypes.string,
  type: PropTypes.string,
};

import partnerHttpService from "containers/partner-module/partner.http.service";
import { PARTNER_URL } from "containers/partner-module/partner.url";
import { UserInfoContext } from "contexts/user.context";
import { useContext } from "react";
import { useHistory } from "react-router";
import messages from "shared/messages";
import sessionStorageService from "shared/sessionStorage";
import { snackbarService } from "shared/snackbar/snackbar.service";

export const ONBOARDING_TYPE = {
  self: "ST_APOLLO_SELF_ONBOARDING",
  assisted: "ST_APOLLO_ASSISTED_ONBOARDING",
  digital: "ST_APOLLO_DIGILOCKER",
};

export const useSwitchOnboarding = () => {
  const { setOnboardingType } = useContext(UserInfoContext);

  const history = useHistory();

  const switchToAssistedOnboarding = () => {
    const payload = {
      codeName: ONBOARDING_TYPE.assisted,
      parentCodeName: "SOURCE_TYPE",
    };

    return new Promise((resolve) => {
      partnerHttpService
        .updateSourceType(sessionStorageService.getProspectId(), payload)
        .then((response) => {
          if (response) setOnboardingType(ONBOARDING_TYPE.assisted);
          resolve();
          history.replace(PARTNER_URL.assisted);
        })
        .catch((err) => {
          snackbarService.show(
            snackbarService.messageType.error,
            err,
            messages.SOURCE_UPDATE_FAILED
          );
          resolve();
        });
    });
  };

  const switchToSelfOnboarding = () => {
    const payload = {
      codeName: ONBOARDING_TYPE.self,
      parentCodeName: "SOURCE_TYPE",
    };

    return new Promise((resolve) => {
      partnerHttpService
        .updateSourceType(sessionStorageService.getProspectId(), payload)
        .then((response) => {
          if (response) setOnboardingType(ONBOARDING_TYPE.self);
          history.push(PARTNER_URL.prospects);
          resolve();
        })
        .catch((err) => {
          snackbarService.show(
            snackbarService.messageType.error,
            err,
            messages.SOURCE_UPDATE_FAILED
          );
          resolve();
        });
    });
  };

  const switchToDigitalOnboarding = () => {
    const payload = {
      codeName: ONBOARDING_TYPE.digital,
      parentCodeName: "SOURCE_TYPE",
    };

    return new Promise((resolve) => {
      partnerHttpService
        .updateSourceType(sessionStorageService.getProspectId(), payload)
        .then((response) => {
          resolve();
          if (response) setOnboardingType(ONBOARDING_TYPE.digital);
          history.push(PARTNER_URL.kyc);
        })
        .catch((err) => {
          snackbarService.show(
            snackbarService.messageType.error,
            err,
            messages.SOURCE_UPDATE_FAILED
          );
          resolve();
        });
    });
  };

  return {
    switchToAssistedOnboarding,
    switchToSelfOnboarding,
    switchToDigitalOnboarding,
  };
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DisclosureIcon from "assets/images/partner/consent/disclosure_icon.svg";
import { ListView } from "components/Basic-view/BasicView";
import HeaderComponent from "components/header/header";
import React, { useState } from "react";
import COLOR_CONST from "shared/theme/color-const";
import classes from "./TermsPage.module.css";
import { TERMS_CONTENT } from "./termsPage.data";

const expandMoreIconStyles = makeStyles({
  root: {
    color: COLOR_CONST.darkBrown,
  },
});

const accordionSummaryStyles = makeStyles({
  root: {
    backgroundColor: COLOR_CONST.lightBlue,
    color: "#273238",
    font: "normal normal bold 16px/30px Comfortaa",
  },
});

const ConsentDivider = () => {
  return <div className={classes.divider_container + " h-2"}></div>;
};

const SubHeader = (props) => {
  return (
    <p className={`font-semibold mb-2 ${props.textStyle}`}>{props.value}</p>
  );
};

const TermsPage = () => {
  const expandMoreIconClasses = expandMoreIconStyles();
  const accordionSummaryClasses = accordionSummaryStyles();

  const [isClicked, setIsClicked] = useState(false);

  return (
    <React.Fragment>
      <HeaderComponent />
      <div className="m-4">
        <div className="flex items-end mb-2">
          <img src={DisclosureIcon} alt="consent" className="pb-2" />
          <h1 className="mt-4 ml-4">{TERMS_CONTENT.heading}</h1>
        </div>
        <p className="text-justify">{TERMS_CONTENT.introSection.content}</p>
        <ListView
          points={TERMS_CONTENT.introSection.informationList}
          pointStyle={"text-black"}
        />
      </div>
      <ConsentDivider />
      <div className="m-4">
        <SubHeader
          value={TERMS_CONTENT.lenderSection.title}
          textStyle={"text-black"}
        />
        <p className="text-justify">{TERMS_CONTENT.lenderSection.content}</p>
      </div>
      <ConsentDivider />
      <div className="m-4">
        <SubHeader
          value={TERMS_CONTENT.thirdPartySection.title}
          textStyle={"text-black"}
        />
        <p className="text-justify">
          {TERMS_CONTENT.thirdPartySection.content}
        </p>
        <ListView
          points={TERMS_CONTENT.thirdPartySection.thirdPartyList}
          pointStyle={"text-black"}
        />
        <p className="text-justify">
          {TERMS_CONTENT.thirdPartySection.thirdPartyLinkContent}{" "}
          <a
            href={TERMS_CONTENT.thirdPartySection.thirdPartyLink}
            className={classes.url + " font-semibold underline"}
            target="_blank"
            rel="noreferrer"
          >
            {TERMS_CONTENT.thirdPartySection.thirdPartyLinkLabel}
          </a>
          .
        </p>
      </div>
      {/* <Divider /> */}
      <p className="m-4 text-justify">
        {TERMS_CONTENT.conclusionSection.content}
      </p>
      <div className="my-6">
        <Accordion>
          <AccordionSummary
            className={accordionSummaryClasses.root}
            expandIcon={
              <ExpandMoreIcon className={expandMoreIconClasses.root} />
            }
            onClick={() => {
              setIsClicked(!isClicked);
            }}
          >
            {TERMS_CONTENT.consent.label}
          </AccordionSummary>
          <AccordionDetails style={{ paddingLeft: "14px" }}>
            <div className="mb-32 text-justify">
              {TERMS_CONTENT.consent.list.map((item) => {
                return <p className="my-3.5 mx-1"> {item}</p>;
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </React.Fragment>
  );
};

export default TermsPage;

export const devEnvironment = {
  appTitle: "Kyros",
  authUrl: "auth/",
  apiUrl: "api/",
  onboardingUrl: "onboarding/",
  // appVersion: '1.0.0',
  // rootUrl: 'https://argus.davinta.com/',
  rootUrl: "https://kyros.davinta.com/",
  // rootUrl: "https://stage01.davinta.com/",

  enableLogs: false,
  httpRequestTimeout: 120000,
  // grant_type: 'password',
  client_id: "kyros-admin-web",
  production: false,
  idleTimeout: 30,
  type: "local",
};

export const CLOUD_VISION_KEY = "AIzaSyAW7IGtPz5YL_Kc79N1mJ7Q-Mxzy5Ryi6k";

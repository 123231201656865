import React from "react";
import Button from "@material-ui/core/Button";
import ButtonLoader from "components/dfs-button-Loader/DfsButtonLoader";
import classes from "./DfsButton.module.css";
import PropType from "prop-types";

const CustomButton = (props) => {
  let showComponent = null;
  if (props.loader) {
    showComponent = (
      <div
        className={`${
          props.buttonFixed
            ? classes.fixed_loader + " mb-5"
            : classes.height_full
        } flex-1 ${props.className}`}
      >
        <div className="flex justify-center items-center">
          <ButtonLoader />
        </div>
      </div>
    );
  } else {
    showComponent = (
      <div
        className={`${
          props.buttonFixed
            ? classes.fixed_bottom + " mb-5"
            : classes.height_full
        } flex-1 ${props.className}`}
      >
        <div className={props.buttonFixed ? "" : classes.height_full}>
          <Button
            id={"button-" + props.buttonId}
            type={props.type ? props.type : "button"}
            style={{ minHeight: "100%" }}
            variant={props.variant ? props.variant : "contained"}
            color={props.color ? props.color : "secondary"}
            fullWidth={props.fullWidth}
            disabled={props.disabled}
            onClick={props.handleClick}
          >
            <span className="title">{props.label}</span>
          </Button>
        </div>
      </div>
    );
  }
  return <React.Fragment>{showComponent}</React.Fragment>;
};

CustomButton.propTypes = {
  loader: PropType.bool,
  buttonFixed: PropType.bool,
  variant: PropType.string,
  color: PropType.string,
  fullWidth: PropType.bool,
  disabled: PropType.bool,
  label: PropType.string.isRequired,
  handleClick: PropType.func,
};

export default React.memo(CustomButton);

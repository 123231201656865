export const PARTNER_URL = {
  home: "/partner/onboarding",
  personalInfo: "prospects/personalDetails/personalInfo",
  personalDocs: "prospects/personalDetails/personalDocuments",
  personalAddress: "prospects/personalDetails/personalAddress",
  businessInfo: "prospects/businessDetails/businessInfo",
  businessDocs: "prospects/businessDetails/businessDocuments",
  bankInfo: "prospects/businessDetails/bankInfo",
  coapplicants: "prospects/otherDetails/coapplicants/list",
  submit: "prospects/otherDetails/submit",
  assisted: "/partner/assisted",
  prospects: "/partner/prospects",
  kyc: "/partner/kyc",
  kycNotFound: "/partner/kyc/notFound",
  kycBusinessDocs: "/partner/kyc/businessDocs",
  agreement: "/partner/agreement",
  kycConclusion: "/partner/kyc/conclusion",
  conclusion: "/partner/conclusion",
};

export const PARTNER_URL_NEW_FLOW = {
  home: "/partner/onboarding",
  register: "/partner/register",
  consent: "/partner/consent",
  personalInfo: "prospects/personalInfo",
  personalDocs: "prospects/personalDocuments",
  personalAddress: "prospects/personalAddress",
  businessInfo: "prospects/businessInfo",
  businessDocs: "prospects/businessDocuments",
  bankInfo: "prospects/bankInfo",
  coapplicants: "prospects/coapplicants/list",
  submit: "prospects/submit",
  assisted: "/partner/assisted",
  prospects: "/partner/prospects",
  kyc: "/partner/kyc",
  kycNotFound: "/partner/kyc/notFound",
  kycBusinessDocs: "/partner/kyc/businessDocs",
  agreement: "prospects/agreement",
  kycConclusion: "/partner/kyc/conclusion",
  conclusion: "/partner/conclusion",
  vkyc: "prospects/vkyc",
  vkyc_verification: "/partner/prospects/vkyc_verification",
  vkyc_failed: "/partner/vkyc/fail",
  vkyc_incomplete: "/partner/vkyc/incomplete",
  vkyc_error: "/partner/vkyc/error",
  verification: "/partner/prospects/verification",
  vkyc_qa_redo: "/partner/vkyc/redo",
  reject: "/partner/reject",
  compliance: "/partner/compliance",
};

import Snackbar from "@material-ui/core/Snackbar";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import React, { PureComponent } from "react";
import { snackbarService } from "shared/snackbar/snackbar.service";
import "./snackbar.css";
import { mobileView } from "shared/theme/media.query";

/**
 * there are exactly 4 type of message can render :-
 * 1.success,2.info,3.warning,4.error
 *
 */
export class SnackBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: "Something went wrong",
      active: false,
      type: "error",
      vertical:"top",
      horizontal: mobileView?"right":"",
    };
  }
  componentDidMount() {
    this.subscription = snackbarService.getMessage().subscribe((data) => {
      if (data) {
        let configMessage = data.text ? data.text : "Something went wrong";

        if (data.err && data.err.status && data.err.status.messageDescription) {
          configMessage = data.err.status.messageDescription;
        }
        let obj = {
          message: configMessage,
          active: true,
          type: data.type ? data.type : "error",
        };
        this.setState({ ...obj });
        snackbarService.clearMessages();
        setTimeout(() => {
          this.setState({ active: false });
        }, 3000);
      }
    });
  }
  render() {
    const { vertical, message, horizontal, type, active } = this.state;
    let icon;
    switch (type) {
      case "error":
        icon = <ReportProblemOutlinedIcon />;
        break;
      case "warning":
        icon = <ReportProblemOutlinedIcon />;
        break;
      case "success":
        icon = <CheckCircleOutlinedIcon />;
        break;
      case "info":
        icon = <InfoOutlinedIcon />;
        break;
      default:
        icon = null;
    }
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={active}//testing purpose
          key={"snackBar"}
        >
          <div className={type}>
            <div className={`${type}_color`}>
              <div> {icon}</div>
             <div className="ml-2">{message}</div>
              
            </div>
          </div>
        </Snackbar>
      </div>
    );
  }
}

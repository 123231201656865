import Grid from "@material-ui/core/Grid";
import {
  OnboardingFlowTypeContext,
  ThemeModeInfoContext,
} from "contexts/user.context";
import React, { useContext } from "react";
import {
  ONBOARDING_FLOW_TYPES,
  THEME_MODE,
} from "shared/utils/Constants/constant";
import { transformDisplayData } from "shared/utils/transform";
import { replacePlaceholder } from "shared/utils/utilities";
import classes from "./BasicView.module.scss";

const BasicView = (props) => {
  return (
    <div>
      <Grid container justify={!props.rowRender ? "space-between" : ""}>
        {props.fields.map((field) => (
          <Grid
            item
            xs={props.rowRender ? 12 : 6}
            sm={props.rowRender ? 12 : 6}
            md={props.rowRender ? 12 : 4}
            lg={props.rowRender ? 12 : 3}
            key={field.key}
          >
            <div
              className={props.rowRender ? classes.fieldRowWise : classes.field}
            >
              <div>
                <p className={classes.title}> {field.title}</p>
                {field.suffixTitleLabel ? (
                  <p className={classes.title + " " + field.suffixTitleClasses}>
                    {field.suffixTitleLabel}
                  </p>
                ) : null}
              </div>
              <div className="flex flex-col">
                <label className={field.valueClass + " " + classes.value}>
                  {`${transformDisplayData(props.data, field)}`}
                </label>
                {field.addBelowValue ? (
                  <p className={"text-right " + classes.value}>
                    {field.addBelowValue}
                  </p>
                ) : null}
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export const getBoldText = (value, matches, index, classes) => {
  let match = matches[index];
  if (!match) return value;
  let p1 = value.split(match[0]);
  return (
    <React.Fragment>
      {p1[0]}
      <b className={classes}>{[match[1]]}</b>
      {getBoldText(p1[1], matches, index + 1, classes) || p1[1]}
    </React.Fragment>
  );
};

export const ListView = (props) => {
  const { themeMode } = useContext(ThemeModeInfoContext);
  const { flowType } = useContext(OnboardingFlowTypeContext);

  const isDarkMode = themeMode === THEME_MODE.DARK;
  const isFlipkartFlow = flowType === ONBOARDING_FLOW_TYPES.FK;
  const textStyle = !isFlipkartFlow && isDarkMode ? "text-white" : "text-black";

  return props.points.map((point) => {
    if (typeof point === "string") {
      point = replacePlaceholder(point, props.data);
      let regIterator = point.matchAll(/<([^>]+)>/g);
      let matches = [...regIterator];

      if (matches.length > 0) {
        return (
          <p className={props.pointStyle + " my-2 flex items-baseline"}>
            <span className={classes.dot}></span>
            <div>{getBoldText(point, matches, 0, textStyle)}</div>
          </p>
        );
      } else {
        return (
          <p className={props.pointStyle + " my-2 flex items-baseline"}>
            <span className={classes.dot}></span>
            {point}
          </p>
        );
      }
    } else if (point.subPoints) {
      return (
        <p className={props.pointStyle + " my-2"}>
          <span className={classes.dot}></span>
          {point.title}
          <div className="ml-4">
            <ListView points={point.subPoints} data={props.data} />
          </div>
        </p>
      );
    } else return null;
  });
};
export default BasicView;

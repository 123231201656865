export const TERMS_CONTENT = {
  heading: "Disclosure!",
  introSection: {
    content:
      "We request your express consent to authorize us to collect, disclose and share the following categories of information in order to provide services:",
    informationList: [
      "KYC information ",
      "Demographic information ",
      "Bank account details ",
      "Business details ",
      "IP address ",
      "Cookies",
    ],
  },
  lenderSection: {
    title: "Consent for information sharing with Lenders",
    content:
      "You authorize and expressly consent us to disclose and share your information with financial institutions, banks or NBFCs for facilitation of a loan or line of credit or purchase of a product on credit.",
  },
  thirdPartySection: {
    title: "Consent for information sharing with third parties",
    content:
      "You authorize and expressly consent us to share and disclose your information with third-parties for provision of services only on a need-to-know basis. The services offered by such third parties include but not limited to:",
    thirdPartyList: [
      "KYC verification ",
      "Bank account verification for disbursement and repayment purposes",
      "eNACH setup for repayment purposes. ",
      "E-sign services",
      "Fraud prevention ",
      "Any other requirement for processing your loan application and for provision of lending services",
    ],
    thirdPartyLinkContent:
      "To access the list of the third parties collecting your information",
    thirdPartyLinkLabel: "Click here",
    thirdPartyLink: "https://www.davintafinserv.com/third_parties.php",
  },
  conclusionSection: {
    content:
      "The consent for the information listed out above may be collected one time or on continuous basis as per applicable law. You may choose to revoke your express consent at any time which could impede or restrict our ability to provide our services to you. We may also continue to retain information necessary to comply with applicable laws and contractual requirements.",
  },
  consent: {
    label: "Consent",
    list: [
      "I/We declare that all the particulars and information given in the application to Davinta Financial Services Private Limited (Davinta) are true, complete, and updated in all respects and I/we have not withheld any information that shall form the basis of my loan. I/We agree that Davinta may forward the application to other Banks/ NBFCs/ Financial Institutions for lending. I/We agree that Davinta/ Banks/ NBFCs will share my personal information to credit bureaus , Central KYC Registry (CERSAI), statutory/ regulatory/ judicial/ quasi-judicial/ enforcement authorities etc., as may be necessary for activities which ancillary / incidental to the business of Davinta and/or to comply with the requirements under Law for the purpose of providing loans/ other financial services. I/we further authorize Davinta to verify / check my / our KYC details from the Central KYC Registry (CERSAI).",
      "I/We agree and confirm the fact that lenders decide the Rate of Interest (ROI) based on risks associated, cost of funds, amount of loan, etc. Thus, ROI may differ from customer-to-customer and loan-to-loan. I/We agree that Davinta/ Banks/ NBFCs/ other Financial Institutions reserve the right to grant or reject this loan application without assigning any reason whatsoever.",
      "I/We agree to and consent to Davinta and/or Banks/ NBFCs/ other Financial Institutions identified by Davinta, to collect, obtain, store, share, and authenticate information including KYC either in parts or full, for providing facility/ loan. The use of the above information shall not amount to any violation of my/ our privacy/ confidentiality rights. I/We authorize Davinta/ Banks/ NBFCs/ Financial Institutions to collect, obtain, store, share, and authenticate the physical copy of Aadhaar card/ physical e-Aadhaar/ masked Aadhaar/ offline electronic Aadhaar XML as issued by UIDAI (Aadhaar) to establish my identity & address proof.",
      "I/We authorize Davinta for verifying/ obtaining information for credit appraisal or providing other financial services, to Davinta to receive, generate, use, retain, store, copy, and transmit my credit bureau records with third-party Banks/ NBFCs/ Financial Institutions.",
      "I/We confirm that I am not a US person nor a resident for Tax purpose in any country other than India.",
    ],
  },
};

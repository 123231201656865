import dateFormat from "dateformat";

const flagResolver = (format, value) => {
  let returnValue = value;

  if (format.staticValue) {
    return format.staticValue;
  }
  if (format.boolean && format.booleanOptional) {
    return value ? "" : "Draft";
  }
  if (format.boolean) {
    return value ? "Yes" : "No";
  }

  if (format.dateWithTime) {
    return dateFormat(value, "dd mmm yyyy h:MM:ss TT");
  }
  if (format.datWithoutTime) {
    // return dateFormat(value, "dd mmm yyyy");
  }
  if (format.dateWithStandardFormat) {
    return dateFormat(value, format.dateWithStandardFormat);
  }
  if (format.pipeToComma) {
    return value ? value.replace(/\|/g, ", ") : "";
  }
  if (format.isName) {
    console.log("flag resolver", value);
    return value
      ? value
          .split(" ")
          .map((w) => w && w[0].toUpperCase() + w.substr(1).toLowerCase())
          .join(" ")
      : value;
  }
  if (format.percentage) {
    var decimalNumber = value.toString().split(".");
    if (
      decimalNumber.length &&
      decimalNumber[1] &&
      decimalNumber[1].length < 3
    ) {
      value = parseFloat(value).toFixed(decimalNumber[1].length);
    } else if (decimalNumber.length < 2) {
      value = parseFloat(value).toFixed();
    } else {
      value = parseFloat(value).toFixed(3);
    }

    return `${value}%`;
  }
  if (format.currency) {
    let result;
    if (value) {
      value = value.toString();
      var lastThree = value.substring(value.length - 3);
      let otherNumbers = value.substring(0, value.length - 3);
      if (otherNumbers !== "") lastThree = "," + lastThree;
      result = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    } else {
      result = value;
    }

    returnValue = result ? `₹${result}` : "";
  }
  if (format.addSuffix) {
    return `${value} ${format.addSuffix}`;
  }

  if (format.showTwo) {
    let splitedArray = value ? value.split("|") : null;

    return splitedArray ? `${splitedArray[0]},${splitedArray[1]}` : value;
  }

  if (format.splitShowStatus) {
    let splitedArray = value ? value.split("-") : null;

    return splitedArray ? `${splitedArray[splitedArray.length - 1]}` : value;
  }

  if (format.defaultValue) {
    return value ? value : format.defaultValue;
  }

  if (format.suffix) {
    returnValue = `${returnValue}*`;
  }

  return returnValue;
};

const nestedKey = (row, column) => {
  if (column.field) {
    const key = column.field;
    let prop;
    const props = key.split(".");
    const iLen = props.length - 1;
    let mRow = row;
    let i = 0;
    for (i = 0; i < iLen; i++) {
      prop = props[i];
      var candidate = mRow[prop];
      if (candidate) {
        mRow = candidate;
      } else {
        break;
      }
    }
    return mRow[props[i]] === undefined && column.alternatValue
      ? column.alternatValue
      : mRow[props[i]];
  } else {
    return "";
  }
};
const resolver = {
  flagResolver,
  nestedKey,
};

export default resolver;

const requestTypes = {
  TOKEN: "TOKEN",
  LOGIN: "LOGIN",
  REFRESH_TOKEN: "REFRESHTOKEN",
  PREAUTH_GET: "PREAUTH_GET",
  PREAUTH_PUT: "PREAUTH_PUT",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  GET: "GET",
  LOGOUT: "LOGOUT",
  FORMDATA: "FORMDATA",
  FILE_UPLOAD: "FILE_UPLOAD",
  DOWNLOAD: "DOWNLOAD",
  DIRECTDOWNLOAD: "DIRECTDOWNLOAD",
  DELETE: "DELETE",
  ENC_KEY: "ENC_KEY",
  REDIRECT: "REDIRECT",
};
const requestItems = [
  {
    name: "token",
    path: "realms/kyros/protocol/openid-connect/token",
    type: "TOKEN",
  },
  {
    name: "refreshToken",
    type: requestTypes.REFRESH_TOKEN,
    path: "realms/kyros/protocol/openid-connect/token",
  },
  {
    name: "fetchByUserName",
    path: "users/fetch-by-username",
    type: requestTypes.POST,
  },
  {
    name: "logout",
    path: "realms/kyros/protocol/openid-connect/logout",
    type: requestTypes.LOGOUT,
  },
  {
    name: "verifyOtp",
    path: "users/{userName}/password/validateOtp/{otp}",
    type: requestTypes.PREAUTH_GET,
  },
  {
    name: "generateOtp",
    path: "users/{userName}/password/generateOtp",
    type: requestTypes.PREAUTH_GET,
  },
  {
    name: "resendOtp",
    type: requestTypes.PREAUTH_GET,
    path: "users/{userName}/password/resendOtp",
  },
  {
    name: "changePassword",
    type: requestTypes.PREAUTH_PUT,
    path: "users/{userName}/password/withoutOtp",
  },
  {
    name: "createProspect", // apollo first api,
    type: requestTypes.POST,
    path: "prospects/v2/basic-info",
  },
  {
    name: "getLocationByPostalCode",
    type: requestTypes.GET,
    path: "locations/getLocation/{postalCd}",
  },
  {
    name: "loanEligibility",
    type: requestTypes.GET,
    path: "loan-applications/{propsectId}/status",
  },
  {
    name: "updateSourceType",
    type: requestTypes.POST,
    path: "prospects/{propsectId}/source-type",
  },
  {
    name: "getPartnerReferral",
    type: requestTypes.GET,
    path: "partner-referral/{refId}",
  },
  {
    name: "bpRedirectConfrimUrl",
    type: requestTypes.GET,
    path: "/partner-referral/bp-redirect/{refId}",
  },
  {
    name: "getAllGenericCodes",
    type: requestTypes.GET,
    path: "generic-codes/find-for-mobile",
  },
  {
    name: "getLastModifiedTime",
    type: requestTypes.GET,
    path: "generic-codes/last-modified-time",
  },
  {
    name: "getProspect",
    path: "prospects/{prospectId}/allDetails",
    type: requestTypes.GET,
  },
  {
    name: "getProspectThumbnail",
    path: "prospects/{prospectId}/documents/{documentId}/thumbnail",
    type: requestTypes.DOWNLOAD,
  },
  {
    name: "prospectDocumentUpload",
    path: "prospects/{prospectId}/documents/upload",
    type: requestTypes.FILE_UPLOAD,
  },
  {
    name: "getAllDocsByProspectId",
    path: "prospects/{prospectId}/documents",
    type: requestTypes.GET,
  },
  {
    name: "upsertProspect",
    path: "prospects/upsert",
    type: requestTypes.PUT,
  },
  {
    name: "upsertCoApplicant",
    path: "prospects/upsert?Main-Prospect-Id={mainProspectId}",
    type: requestTypes.PUT,
  },
  {
    name: "createBankAccount",
    path: "prospects/{prospectId}/accounts",
    type: requestTypes.POST,
  },
  {
    name: "updateBankAccount",
    path: "prospects/{prospectId}/accounts",
    type: requestTypes.PUT,
  },
  {
    name: "upsertBusinessDetails",
    path: "prospects/business-addendum/{prospectId}/upsert",
    type: requestTypes.POST,
  },
  {
    name: "postDocuments",
    path: "documents",
    type: requestTypes.FORMDATA,
  },
  {
    name: "upsertAddress",
    path: "prospects/{prospectId}/addresses/upsert",
    type: requestTypes.PUT,
  },
  {
    name: "getCoApplicants",
    path: "prospects/{prospectId}/co-applicants",
    type: requestTypes.GET,
  },
  {
    name: "addressDocumentUpload",
    path: "prospects/{prospectId}/addresses/upload",
    type: requestTypes.FILE_UPLOAD,
  },
  {
    name: "submitMsme",
    path: "prospects/{prospectId}/credit-requirements/msme-submission/{creditRequirementId}",
    type: requestTypes.POST,
  },
  {
    name: "deleteProspect",
    path: "prospects/{prospectId}",
    type: requestTypes.DELETE,
  },
  {
    name: "getBankDetailsByIfsc",
    type: requestTypes.GET,
    path: "ifsc-codes/getBankDetailsByIfscCode/{ifscCode}",
  },

  {
    name: "submitRating",
    type: requestTypes.POST,
    path: "customer-feedbacks",
  },
  {
    name: "mergeAndUploadDocument",
    path: "documents/merge-upload",
    type: requestTypes.FILE_UPLOAD,
  },
  {
    name: "getProspectInline",
    path: "prospects/{entityId}/documents/{documentId}/inline",
    type: requestTypes.DOWNLOAD,
  },
  {
    name: "updateApplicationStatus",
    path: "prospects/{prospectId}/credit-requirements/{creditRequirementId}/status?applicationStatus={applicationStatus}",
    type: requestTypes.PUT,
  },
  {
    name: "authorizeDigiLocker",
    path: "digilocker/redirect?prospect-id={prospectId}",
    type: requestTypes.GET,
  },
  {
    name: "processDigiLockerFiles",
    path: "digilocker/fetch?code={code}&&prospect-id={prospectId}",
    type: requestTypes.POST,
  },
  {
    name: "getLoanAgreement",
    path: "loan-agreement/msme/{prospectId}/{creditReqId}/download-template",
    type: requestTypes.DOWNLOAD,
  },
  {
    name: "downloadLoanAgreement",
    path: "loan-agreement/msme/{prospectId}/{creditReqId}/download-template",
    type: requestTypes.DIRECTDOWNLOAD,
  },
  {
    name: "completeLoanAgreement",
    path: "loan-agreement/msme/{prospectId}/{loanApplicationId}/complete?digital-signature={signatureStatus}",
    type: requestTypes.POST,
  },
  {
    name: "downloadDocument",
    path: "customer-documents/{documentId}/download",
    type: requestTypes.DIRECTDOWNLOAD,
  },
  {
    name: "downloadSanctionLetter",
    path: "sanctionemail/downloadSanctionLetter/{prospectId}/{creditReqId}",
    type: requestTypes.DIRECTDOWNLOAD,
  },
  {
    name: "findAllOffers",
    path: "paylater-offers/find-all-offers",
    type: requestTypes.GET,
  },
  {
    name: "getAllConfigurations",
    path: "configurations/{groupCd}/get-all",
    type: requestTypes.GET,
  },
  {
    name: "createCustomerPostDigiLocker",
    path: "digilocker/create-customer?prospect-id={prospectId}",
    type: requestTypes.POST,
  },
  {
    name: "optUserRequest",
    path: "gupshup/opt-user",
    type: requestTypes.POST,
  },
  {
    name: "initiateOkycVkyc",
    path: "vkyc/{prospectId}/initiate-okyc-vkyc?apiSource=apollo-onboarding",
    type: requestTypes.POST,
  },
  {
    name: "getLoanAgreementDetails",
    path: "loan-agreement/msme/{customerId}/{loanApplicationId}/generate?esign-redirect=true",
    type: requestTypes.POST,
  },
  {
    name: "getDigitapStatusLite",
    path: "vkyc/digitap/get-status-lite?prospectId={prospectId}&applicationId={applicationId}",
    type: requestTypes.GET,
  },
  {
    name: "fetchEsignStatus",
    path: "loan-agreement/msme/{prospectId}/{applicationId}/esign-status",
    type: requestTypes.POST,
  },
  {
    name: "updateConsent",
    path: "partner-referral/{refId}/consent",
    type: requestTypes.POST,
  },
  {
    name: "getCustomerFeedbackDetails",
    path: "customer-feedbacks/{customerId}",
    type: requestTypes.GET,
  },
  {
    name: "updateDocumentPassword",
    path: "prospects/{prospectId}/documents/{documentId}/doc-password/{docPassword}",
    type: requestTypes.POST,
  },
  {
    name: "deleteProspectDocument",
    path: "prospects/{prospectId}/documents/{documentId}",
    type: requestTypes.DELETE,
  },
  {
    name: "getSessionKey",
    path: "sessionKey",
    type: requestTypes.ENC_KEY,
  },
  {
    name: "getApplicationConfiguration",
    path: "loan-applications/{propsectId}/configuration?device-os={deviceOs}",
    type: requestTypes.GET,
  },
  {
    name: "getAllCustomerDetailsById",
    path: "customers/{customerId}/allDetails",
    type: requestTypes.GET,
  },
  {
    name: "findByCifId",
    path: "mandate-registration/{cifId}/cif-id",
    type: requestTypes.GET,
  },
  {
    name: "redirectWithTokenSwitch",
    path: "customers/onboard-customer/redirect/token-switch?customer-id={customerId}&redirect-type={redirectType}",
    type: requestTypes.REDIRECT,
  },
];
const getApi = (request) => {
  const reqItem = requestItems.find((api) => api.name === request.name);
  if (reqItem) {
    return reqItem;
  } else {
    console.log("Unable to find the request");
    return null;
  }
};
const apiConfig = { requestTypes, requestItems, getApi };

export default apiConfig;

import React from "react";
import "./DfsLoader.css";

const DfsLoader = (props) => {
  return (
    <React.Fragment>
      {props.hideOverlay ? null : <div className={"modal-overlay"} />}
      <div className="global-loader">
        <div className=" loadingio-spinner-pulse-l6imaz837x">
          <div className="ldio-igiffh5vp5">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DfsLoader;

import { ThemeProvider } from "@material-ui/core";
import DfsLoader from "components/Dfs-loader/DfsLoader";
import { SnackBar } from "components/UI/snack-bar/SnackBar";
import IFrame from "components/iframe/iFrame";
import { getSessionKey } from "containers/login/login.http.service";
import { useApplicationConfig } from "containers/partner-module/partner-container/hooks/useApplicationConfig";
import partnerHttpService, {
  getProspect,
} from "containers/partner-module/partner.http.service";
import { ConfigurationContext } from "contexts/configuration.context";
import { IFrameContext } from "contexts/iframe.context";
import {
  OnboardingFlowTypeContext,
  ReferralInfoContext,
  ThemeModeInfoContext,
  UserInfoContext,
} from "contexts/user.context";
import React, { useCallback, useContext, useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import messages from "shared/messages";
import sessionStorage, {
  default as sessionStorageService,
} from "shared/sessionStorage";
import {
  persistentSnackbarService,
  snackbarService,
} from "shared/snackbar/snackbar.service";
import COLOR_CONST from "shared/theme/color-const";
import {
  ONBOARDING_FLOW_TYPES,
  THEME_MODE,
} from "shared/utils/Constants/constant";
import { globalStoreState } from "store/global-store/global-store-action";
import ConfigureGlobalStore from "store/global-store/global-store-config";
import "./App.css";
import { ONBOARDING_THEME } from "./shared/theme/theme";
import TermsPage from "containers/terms-page/TermsPage";

const Partner = React.lazy(() =>
  import("./containers/partner-module/partner.route")
);

const SupportPageRoute = React.lazy(() =>
  import("containers/support-page/supportPage.route")
);

const getLoader = () => {
  return <DfsLoader />;
};

function App() {
  const { setOnboardingType, setLoanStatus, setUserInfo } =
    useContext(UserInfoContext);
  const { setReferralInfo } = useContext(ReferralInfoContext);
  const { flowType, setFlowType } = useContext(OnboardingFlowTypeContext);
  const { themeMode, setThemeMode } = useContext(ThemeModeInfoContext);
  const { setOffers } = useContext(ConfigurationContext);
  const { src } = useContext(IFrameContext);
  const { getApplicationConfig } = useApplicationConfig();

  const root = document.querySelector(":root");

  const setVariables = () => {
    let variables = {
      "--primary": COLOR_CONST.primary,
      "--secondary": COLOR_CONST.secondary,
      "--background-color": COLOR_CONST.white,
      "--heading-color": COLOR_CONST.heading,
      "--paragraph-color": COLOR_CONST.textSecondary,
      "--pop-up-background-color": COLOR_CONST.white,
      "--background-wrapper-color": COLOR_CONST.lightBlue,
      "--disabled-color": COLOR_CONST.textSecondary,
      "--dot-color": COLOR_CONST.primary,
      "--border-color": COLOR_CONST.borderColor,
      "--stepper-label-color": COLOR_CONST.secondary,
      "--stepper-background-color": COLOR_CONST.lightBlue,
      "--snack-bar-background-color": COLOR_CONST.snackBarBackground,
      "--snack-bar-text-color": COLOR_CONST.lightBrown,
      "--icon-filter":
        "brightness(0) saturate(100%) invert(35%) sepia(92%) saturate(1998%) hue-rotate(188deg) brightness(107%) contrast(107%)",
    };

    if (
      themeMode === THEME_MODE.DARK &&
      flowType !== ONBOARDING_FLOW_TYPES.FK
    ) {
      variables = {
        "--primary": COLOR_CONST.cyan,
        "--secondary": COLOR_CONST.cyan,
        "--background-color": COLOR_CONST.black,
        "--heading-color": COLOR_CONST.darkGray,
        "--paragraph-color": COLOR_CONST.gray,
        "--pop-up-background-color": COLOR_CONST.popUpBackground,
        "--background-wrapper-color": COLOR_CONST.popUpBackground,
        "--disabled-color": COLOR_CONST.lightBlack,
        "--dot-color": COLOR_CONST.yellow,
        "--border-color": COLOR_CONST.darkBorderColor,
        "--stepper-label-color": COLOR_CONST.cyan,
        "--stepper-background-color": COLOR_CONST.darkBackGround,
        "--snack-bar-background-color": COLOR_CONST.lightBrown,
        "--snack-bar-text-color": COLOR_CONST.white,
        "--icon-filter":
          "brightness(0) saturate(100%) invert(68%) sepia(86%) saturate(2372%) hue-rotate(125deg) brightness(98%) contrast(103%)",
      };
    } else if (
      flowType === ONBOARDING_FLOW_TYPES.INTERIM ||
      window.location.hash === "#/terms"
    ) {
      variables = {
        "--primary": COLOR_CONST.darkBrown,
        "--secondary": COLOR_CONST.darkGreen,
        "--background-color": COLOR_CONST.white,
        "--heading-color": COLOR_CONST.heading,
        "--paragraph-color": COLOR_CONST.textSecondary,
        "--pop-up-background-color": COLOR_CONST.white,
        "--background-wrapper-color": COLOR_CONST.lightBlue,
        "--disabled-color": COLOR_CONST.textSecondary,
        "--dot-color": COLOR_CONST.yellow,
        "--border-color": COLOR_CONST.borderColor,
        "--stepper-label-color": COLOR_CONST.darkBrown,
        "--stepper-background-color": COLOR_CONST.lightBlue,
        "--snack-bar-background-color": COLOR_CONST.snackBarBackground,
        "--snack-bar-text-color": COLOR_CONST.lightBrown,
        "--icon-filter":
          "brightness(0) saturate(100%) invert(9%) sepia(65%) saturate(4215%) hue-rotate(351deg) brightness(91%) contrast(99%)",
      };
    }

    Object.entries(variables).forEach((variable) =>
      root.style.setProperty(variable[0], variable[1])
    );
  };

  setVariables();

  setInterval(() => {
    if (navigator.onLine) {
      persistentSnackbarService.show(
        persistentSnackbarService.key.BAD_CONNECTION,
        false
      );
    } else {
      persistentSnackbarService.show(
        persistentSnackbarService.key.BAD_CONNECTION,
        true
      );
    }
  }, 10 * 1000);

  const loadData = useCallback(async () => {
    if (!window.location.hostname.includes("support")) {
      try {
        await getSessionKey();
      } catch (err) {
        snackbarService.show(
          snackbarService.messageType.error,
          err,
          messages.COMMON_ERROR_MESSAGE
        );
      }
    }

    let referralId = sessionStorage.getReferralId();
    if (referralId) {
      partnerHttpService
        .getRefralDetail(referralId)
        .then((response) => {
          setFlowType(
            response.apolloOnboardingFlow ? response.apolloOnboardingFlow : 0
          );
          setReferralInfo(response);
          setOnboardingType(response.sourceType);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }

    let prospectId = sessionStorageService.getProspectId();
    if (prospectId) {
      partnerHttpService
        .eligibilityLoan()
        .then((response) => setLoanStatus(response))
        .catch((err) => {
          console.log(err);
        });

      getProspect(prospectId)
        .then((response) => setUserInfo(response))
        .catch((err) => {
          snackbarService.show(
            snackbarService.messageType.error,
            null,
            messages.FETCH_FAILED
          );
        });
      getApplicationConfig();
    }

    if (sessionStorageService.getAccessToken()) {
      partnerHttpService
        .getAllOffers()
        .then((offers) => {
          setOffers(offers);
        })
        .catch(() => {
          snackbarService.show(
            snackbarService.messageType.error,
            null,
            messages.OFFER_FETCH_FAILED
          );
        });
    }
  }, [
    setFlowType,
    setLoanStatus,
    setOffers,
    setOnboardingType,
    setReferralInfo,
    setUserInfo,
    getApplicationConfig,
  ]);

  useEffect(() => {
    ConfigureGlobalStore(globalStoreState);
    const theme = sessionStorage.getTheme("theme");
    if (theme === "DARK_BRIGHTNESS") {
      setThemeMode(THEME_MODE.DARK);
    } else if (theme === "LIGHT_BRIGHTNESS") {
      setThemeMode(THEME_MODE.LIGHT);
    } else {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        setThemeMode(THEME_MODE.DARK);
      } else {
        setThemeMode(THEME_MODE.LIGHT);
      }

      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", (event) => {
          if (event.matches) {
            setThemeMode(THEME_MODE.DARK);
          } else {
            setThemeMode(THEME_MODE.LIGHT);
          }
        });
    }

    loadData();
  }, [setThemeMode, loadData]);

  return (
    <ThemeProvider theme={ONBOARDING_THEME[flowType][themeMode]}>
      <HashRouter>
        <SnackBar />
        <React.Suspense fallback={getLoader()}>
          <Switch>
            <Route
              path="/partner"
              name="partner module"
              render={(props) => (src ? <IFrame /> : <Partner {...props} />)}
            />
            <Route
              path="/terms"
              name="terms module"
              render={(props) => <TermsPage {...props} />}
            />
            <Route
              path="/*"
              name="support module"
              render={(props) => <SupportPageRoute {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;

import { Subject } from "rxjs";

const subject = new Subject();
const persistentSubject = new Subject();

export const snackbarService = {
  show: (messagetype, err = null, message = "Something went wrong!") =>
    subject.next({ text: message, err: err, type: messagetype }),
  clearMessages: () => subject.next(),
  getMessage: () => subject.asObservable(),
  messageType: {
    error: "error",
    warning: "warning",
    info: "info",
    success: "success",
  },
};

export const persistentSnackbarService = {
  show: (key, show) =>
  persistentSubject.next({ key, show }),
  getMessage: () => persistentSubject.asObservable(),
  key: {
    BAD_CONNECTION: "BAD_CONNECTION",
    DIGILOCKER_DISABLED: "DIGILOCKER_DISABLED",
  }
};

export const ParentServiceKey = {
  PARTNER: "PARTNER",
};

export const buttonType = {
  SUBMIT: "SUBMIT",
  CANCEL: "CANCEL",
};

export const GENRIC_PARENT_CODE = {
  BESTPRICE_APPLICATION_STATUS: "BESTPRICE_APPLICATION_STATUS",
};

export const support_number = "080-471-85381";

export const BUSINESS_TYPES = {
  PROPRIETORSHIP: "BS_PROPRIETORSHIP",
  PARTNERSHIP: "BS_PARTNERSHIP",
  PVT_LTD: "BS_PRIVATE_LIMITED_COMPANY",
  INDIVIDUAL: "BS_INDIVIDUAL",
};

export const AUTOPAY_REQUIRED_SEGMENTS = [20, 21, 22];

export const ONBOARDING_FLOW_TYPES = {
  FK: 0,
  INTERIM: 1,
};

export const THEME_MODE = {
  DARK: "dark",
  LIGHT: "light",
};

export const INTERNET_CONNECTION = {
  ONLINE: "online",
  OFFLINE: "offline",
};

export const VKYC_STATUS = {
  APPROVED: "VKYC_APPROVED",
  REJECTED: "VKYC_REJECTED",
  INCOMPLETE: "VKYC_INCOMPLETE",
  QA_REJECTED: "VKYC_QA_REJECTED",
  QA_REDO: "VKYC_QA_REDO",
  QA_PENDING: "VKYC_QA_PENDING",
};

export const LOAN_STATUS = {
  INCOMPLETE_APPLICATION: "BP_INCOMPLETE_APPLICATION",
  VERIFICATION_IN_PROGRESS: "BP_VERIFICATION_IN_PROGRESS",
  VKYC_PENDING_QA_APPROVAL: "BP_VKYC_PENDING_QA_APPROVAL",
  PENDING_LOAN_AGREEMENT: "BP_PENDING_LOAN_AGREEMENT",
  PENDING_CO_APPLICANT_LOAN_AGREEMENT: "BP_PENDING_CO_APPLICANT_LOAN_AGREEMENT",
  PENDING_VKYC: "BP_PENDING_VKYC",
  LOAN_AGREEMENT_COMPLETED: "BP_LOAN_AGREEMENT_COMPLETED",
  LOAN_SANCTIONED: "BP_LOAN_SANCTIONED",
  LOAN_APPROVED: "BP_LOAN_APPROVED",
};

export const PARTNER_REDIRECTION_LIST = ["ANCHOR-WALMART", "ANCHOR-FKW"];

export const ANCHOR_URLS = [
  "flipkart",
  "supply-chain",
  "distributor",
  "ninjacart",
  "solv",
  "online-anchors",
];

export const ANCHOR = {
  NINJACART: "ANCHOR-NINJACART",
  ARZOOO: "ANCHOR-ARZOOO",
};

export const AGREEMENT_TYPE = {
  E_SIGN: "AT_E_AGREEMENT",
  CLICKWRAP: "AT_CLICKWRAP",
};

export const REDIRECTION_TYPE = {
  SERVICING_HOME: "TXN_HOME",
  AUTOPAY: "AUTOPAY"
}

export const whatsappSupportNo = "9972900968";

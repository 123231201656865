export const toTitleCase = function (string) {
  return (
    string?.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
  );
};

export const isObjectEmpty = (value) => {
  return (
    Object.prototype.toString.call(value) === "[object Object]" &&
    JSON.stringify(value) === "{}"
  );
};

/**
 * Appends ordinal to a number. Eg: 31 returns 31st.
 * @param {Number} number
 * @returns number appended with ordinal
 */
export const appendOrdinal = (number) => {
  let superscript = "th";
  if(number % 10 === 1){
    superscript = "st";
  } else if(number % 10 === 2){
    superscript = "nd";
  } else if(number % 10 === 3){
    superscript = "rd";
  }
  number += superscript;
  return number;
}

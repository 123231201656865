import { createContext, useState } from "react";
import { isObjectEmpty } from "shared/utils/util";

export const UserInfoContext = createContext({
  userInfo: null,
  setUserInfo: () => {},
  onboardingType: null,
  setOnboardingType: () => {},
  loanStatus: null,
  setLoanStatus: () => {},
});

export const ReferralInfoContext = createContext({
  referralInfo: null,
  setReferralInfo: () => {},
  isReferralInfoLoaded: () => {},
});

export const OnboardingFlowTypeContext = createContext({
  flowType: 0,
  setFlowType: () => {},
});

export const ThemeModeInfoContext = createContext({
  themeMode: "light",
  setThemeMode: () => {},
});

export const InternetConnectionContext = createContext({
  internetConnection: "online",
  setInternetConnection: () => {},
});

export const UserInfoContextProvider = (props) => {
  const [userInfo, setUserInfo] = useState({});
  const [onboardingType, setOnboardingType] = useState();
  const [loanStatus, setLoanStatus] = useState({});
  const [internetConnection, setInternetConnection] = useState("online");
  const [referralInfo, setReferralInfo] = useState({});
  const [themeMode, setThemeMode] = useState("light");
  const [flowType, setFlowType] = useState(0);

  const isReferralInfoLoaded = () => {
    return !isObjectEmpty(referralInfo);
  };

  return (
    <ReferralInfoContext.Provider
      value={{
        referralInfo,
        setReferralInfo,
        isReferralInfoLoaded,
      }}
    >
      <UserInfoContext.Provider
        value={{
          userInfo,
          setUserInfo,
          onboardingType,
          setOnboardingType,
          loanStatus,
          setLoanStatus,
        }}
      >
        <OnboardingFlowTypeContext.Provider value={{ flowType, setFlowType }}>
          <ThemeModeInfoContext.Provider value={{ themeMode, setThemeMode }}>
            <InternetConnectionContext.Provider
              value={{ internetConnection, setInternetConnection }}
            >
              {props.children}
            </InternetConnectionContext.Provider>
          </ThemeModeInfoContext.Provider>
        </OnboardingFlowTypeContext.Provider>
      </UserInfoContext.Provider>
    </ReferralInfoContext.Provider>
  );
};

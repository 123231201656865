import { ONBOARDING_FLOW_TYPES } from "shared/utils/Constants/constant";
import { LENDER } from "shared/utils/Constants/lender-constant";
import { ONBOARDING_TYPE } from "../../hooks/useSwitchOnboarding";

export const ROUTE_CONFIG = {
  INTERIM_NON_DIGILOCKER: {
    personalInfo: {
      title: "Personal info",
      progress: 33.34,
      nextScreen: "personalDocuments",
      section: "personalDetails",
      blockAfterSubmission: true,
    },
    personalDocuments: {
      title: "Upload your documents",
      progress: 33.34,
      nextScreen: "personalAddress",
      section: "personalDetails",
      blockAfterSubmission: true,
    },
    personalAddress: {
      title: "Personal address",
      progress: 33.34,
      nextScreen: "coapplicants",
      section: "personalDetails",
      blockAfterSubmission: true,
    },
    coapplicants: {
      nextScreen: "businessInfo",
      section: "personalDetails",
      hideHeader: true,
      blockAfterSubmission: true,
    },
    businessInfo: {
      title: "Business info",
      progress: 50,
      nextScreen: "businessDocuments",
      section: "businessDetails",
      blockAfterSubmission: true,
    },
    businessDocuments: {
      title: "Upload business document",
      progress: 50,
      nextScreen: "vkyc",
      section: "businessDetails",
      blockAfterSubmission: true,
      submitBreakPoint: true,
    },
    vkyc: {
      nextScreen: "vkyc_verification",
      section: "compliance",
      hideHeader: true,
    },
    vkyc_verification: {
      nextScreen: "verification",
      section: "compliance",
      hideHeader: true,
      onBackRedirectTo: "/partner/onboarding",
    },
    verification: {
      nextScreen: "agreement",
      section: "compliance",
      hideHeader: true,
    },
    agreement: {
      section: "compliance",
      hideHeader: true,
    },
  },
  INTERIM_DIGILOCKER: {
    personalInfo: {
      title: "Personal info",
      progress: 50,
      nextScreen: "personalAddress",
      section: "personalDetails",
      blockAfterSubmission: true,
    },
    personalAddress: {
      title: "Personal address",
      progress: 100,
      nextScreen: "coapplicants",
      section: "personalDetails",
      blockAfterSubmission: true,
    },
    coapplicants: {
      nextScreen: "businessInfo",
      section: "personalDetails",
      hideHeader: true,
      blockAfterSubmission: true,
    },
    businessInfo: {
      title: "Business info",
      progress: 50,
      nextScreen: "businessDocuments",
      blockAfterSubmission: true,
      section: "businessDetails",
    },
    businessDocuments: {
      title: "Upload business document",
      progress: 50,
      nextScreen: "vkyc",
      section: "businessDetails",
      blockAfterSubmission: true,
      submitBreakPoint: true,
    },
    vkyc: {
      nextScreen: "vkyc_verification",
      section: "compliance",
      hideHeader: true,
    },
    vkyc_verification: {
      nextScreen: "verification",
      section: "compliance",
      hideHeader: true,
      onBackRedirectTo: "/partner/onboarding",
    },
    verification: {
      nextScreen: "agreement",
      section: "compliance",
      hideHeader: true,
    },
    agreement: {
      section: "compliance",
      hideHeader: true,
    },
  },
  FK_SELF: {
    personalInfo: {
      title: "Personal info",
      progress: 33.34,
      nextScreen: "personalDocuments",
      section: "personalDetails",
    },
    personalDocuments: {
      title: "Upload your documents",
      progress: 33.34,
      nextScreen: "personalAddress",
      section: "personalDetails",
    },
    personalAddress: {
      title: "Personal address",
      progress: 33.34,
      nextScreen: "businessInfo",
      section: "personalDetails",
    },
    businessInfo: {
      title: "Business info",
      progress: 33.34,
      nextScreen: "businessDocuments",
      section: "businessDetails",
    },
    businessDocuments: {
      title: "Upload business document",
      progress: 33.34,
      nextScreen: "bankInfo",
      section: "businessDetails",
    },
    bankInfo: {
      title: "Bank info",
      progress: 33.34,
      nextScreen: "coapplicants",
      section: "businessDetails",
    },
    coapplicants: {
      nextScreen: "submit",
      section: "otherDetails",
      hideHeader: true,
      blockAfterSubmission: true,
    },
    submit: {
      // nextScreen: "submit",
      section: "otherDetails",
      hideMargin: true,
      hideHeader: true,
    },
  },
  FK_KVB_DIGILOCKER: {
    personalInfo: {
      title: "Personal info",
      progress: 33.34,
      nextScreen: "businessInfo",
      section: "personalDetails",
    },
    businessInfo: {
      title: "Business info",
      progress: 33.34,
      nextScreen: "businessDocuments",
      section: "businessDetails",
    },
    businessDocuments: {
      title: "Upload business document",
      progress: 33.34,
      nextScreen: "coapplicants",
      section: "businessDetails",
    },
    coapplicants: {
      nextScreen: "submit",
      section: "otherDetails",
      hideHeader: true,
      blockAfterSubmission: true,
    },
    submit: {
      section: "otherDetails",
      hideMargin: true,
      hideHeader: true,
    },
  },
  FK_KVB_NON_DIGILOCKER: {
    personalInfo: {
      title: "Personal info",
      progress: 33.34,
      nextScreen: "personalDocuments",
      section: "personalDetails",
    },
    personalDocuments: {
      title: "Upload your documents",
      progress: 33.34,
      nextScreen: "personalAddress",
      section: "personalDetails",
    },
    personalAddress: {
      title: "Personal address",
      progress: 33.34,
      nextScreen: "businessInfo",
      section: "personalDetails",
    },
    businessInfo: {
      title: "Business info",
      progress: 33.34,
      nextScreen: "businessDocuments",
      section: "businessDetails",
    },
    businessDocuments: {
      title: "Upload business document",
      progress: 33.34,
      nextScreen: "coapplicants",
      section: "businessDetails",
    },
    coapplicants: {
      nextScreen: "submit",
      section: "otherDetails",
      hideHeader: true,
      blockAfterSubmission: true,
    },
    submit: {
      section: "otherDetails",
      hideHeader: true,
      hideMargin: true,
    },
  },
};

export const getRouteConfig = (flowType, sourceType, eKycCompleted, lender) => {
  if (flowType === ONBOARDING_FLOW_TYPES.INTERIM) {
    if (
      sourceType === ONBOARDING_TYPE.digital ||
      (sourceType === ONBOARDING_TYPE.assisted && eKycCompleted)
    ) {
      return ROUTE_CONFIG.INTERIM_DIGILOCKER;
    }
    return ROUTE_CONFIG.INTERIM_NON_DIGILOCKER;
  } else {
    if (lender === LENDER.KVB) {
      if (sourceType === ONBOARDING_TYPE.digital) {
        return ROUTE_CONFIG.FK_KVB_DIGILOCKER;
      } else {
        return ROUTE_CONFIG.FK_KVB_NON_DIGILOCKER;
      }
    }
    return ROUTE_CONFIG.FK_SELF;
  }
};

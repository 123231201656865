import { useEffect, useState } from "react";

let globalState = {};
let listeners = [];
let actions = {};

export const useGlobalStore = (shouldListen = true) => {
  const [ , setState] = useState(globalState);

  const dispatch = (identifier, payload) => {
    if (actions[identifier]) {
      const newState = actions[identifier](globalState, payload);
      globalState = { ...globalState, ...newState };
      for (const listener of listeners) {
        listener(globalState);
      }
    }
  };

  useEffect(
    () => {
      if (shouldListen) {
        listeners.push(setState);
      }

      return () => {
        if (shouldListen) {
          listeners = listeners.filter((li) => li !== setState);
        }
      };
    },
    [setState],
    shouldListen
  );

  return [globalState, dispatch];
};

export const initGlobalStore = (userActions, initialState) => {
  if (initialState) {
    globalState = { ...initialState };
  }

  actions = { ...actions, ...userActions };
};

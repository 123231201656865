let sessionStorageService = {};
sessionStorageService.setAccessToken = (accessToken) => {
  sessionStorage.setItem("accessToken", accessToken);
};
sessionStorageService.setProspectId = (prospectId) => {
  sessionStorage.setItem("prospectId", prospectId);
};

sessionStorageService.setCreditReqId = (creditReqId) => {
  sessionStorage.setItem("creditRequirementId", creditReqId);
};

sessionStorageService.setReferralId = (referralId) => {
  sessionStorage.setItem("referralId", referralId);
};

sessionStorageService.getAccessToken = () => {
  return sessionStorage.getItem("accessToken");
};
sessionStorageService.getProspectId = () => {
  return sessionStorage.getItem("prospectId");
};

sessionStorageService.getCreditRequirmentId = () => {
  return sessionStorage.getItem("creditRequirementId");
};

sessionStorageService.getReferralId = () => {
  return sessionStorage.getItem("referralId");
};

sessionStorageService.setClientId = (clientId) => {
  sessionStorage.setItem("clientId", clientId);
};

sessionStorageService.getClientId = () => {
  return sessionStorage.getItem("clientId");
};

sessionStorageService.setTheme = (theme) => {
  sessionStorage.setItem("theme", theme);
};

sessionStorageService.getTheme = () => {
  return sessionStorage.getItem("theme");
};

export default sessionStorageService;

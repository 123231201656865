import { createContext, useMemo, useState } from "react";

export const ConfigurationContext = createContext({
  offers: null,
  setOffers: () => {},
  onboardingConfig: null,
  setOnboardingConfig: () => {},
  applicationConfig: {
    apolloOnboardingFlow: 0,
    assistedOnboardingEnabled: true,
    digilockerEnabled: true,
    selfOnboardingEnabled: true,
    autoPayEnabled: true,
    autoPayMandatory: true,
    vkycEnabled: true,
    vkycMandatory: true,
  },
  setApplicationConfig: () => {},
});

export const ConfigurationContextProvider = (props) => {
  const [offers, setOffers] = useState([]);
  const [onboardingConfig, setOnboardingConfig] = useState([]);
  const [applicationConfig, setApplicationConfig] = useState({
    apolloOnboardingFlow: 0,
    assistedOnboardingEnabled: true,
    digilockerEnabled: true,
    selfOnboardingEnabled: true,
  });

  const value = useMemo(() => {
    return {
      offers,
      setOffers,
      onboardingConfig,
      setOnboardingConfig,
      applicationConfig,
      setApplicationConfig,
    };
  }, [offers, onboardingConfig, applicationConfig]);

  return (
    <ConfigurationContext.Provider value={value}>
      {props.children}
    </ConfigurationContext.Provider>
  );
};

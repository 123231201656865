const messages = {
  BAD_CONNECTION: "Uh-no! You are offline",
  msmeConsent: "I hereby accept all the Terms & Conditions",
  COMMON_UPLOAD_FILE_ERR: "Unable to upload file. Please try again",
  COMMON_DOWNLOAD_FILE_ERR: "Unable to download file. Please try again",
  PANCARD_FAILED:
    "Your name doesn't match with PAN details. Please verify Name/PAN",
  FILE_SIZE_ERR: "Upload failed. File size should not exceed 15MB.",
  COMMON_ERROR_MESSAGE: "Something went wrong!",

  AADHAAR_UPLOAD_FAILED: "Unable to upload proof of Aadhaar",
  INVALID_AADHAAR: "Invalid proof of Aadhaar",
  PAN_UPLOAD_FAILED: "Unable to upload PAN",
  PROFILE_PHOTO_UPLOAD_FAILED: "Unable to upload profile photo",
  ADDRESS_PROOF_UPLOAD_FAILED: "Unable to upload address proof",
  GST_UPLOAD_FAILED: "Unable to upload GST statement",
  PARTNERSHIP_DEED_UPLOAD_FAILED: "Unable to upload partnership deed",
  MOA_UPLOAD_FAILED: "Unable to upload MOA Document",
  BUSINESS_PAN_UPLOAD_FAILED: "Unable to upload Business PAN",
  BUSINESS_PROOF_UPLOAD_FAILED: "Unable to upload business proof",
  CHEQUE_UPLOAD_FAILED: "Unable to upload cheque",
  BANK_STATEMENT_UPLOAD_FAILED: "Unable to upload bank statement",

  BUSINESS_UPDATE_FAILED: "Unable to update business information",
  BANK_ACC_UPDATE_FAILED: "Unable to update bank account details",
  prospect_update_success: "Personal details updated",
  PROSPECT_UPDATE_FAILED: "Unable to update personal details",
  FETCH_FAILED: "Unable to fetch updated details",
  ADDRESS_UPDATE_FAILED: "Unable to update address details",
  COAPP_UPDATE_FAILED: "Unable to update Co-Applicant",
  APPLICATION_SUBMIT_FAILED: "Failed to submit the application",
  SOURCE_UPDATE_FAILED: "Failed to change onboarding type",
  APPLICATION_RATING_FAILED: "Failed to submit the rating",
  AGREEMENT_ACCEPT_FAILED: "Failed to accept loan agreement",
  REDIRECT_FAILED: "Failed to redirect",
  WHATSAPP_OPT_IN_FAILED: "Failed to subscribe to whatsapp notifications",
  CONSENT_ACCEPT_FAILED: "Failed to update consent",
  STATUS_UPDATE_FAILED: "Failed to get updated application status",

  OFFER_FETCH_FAILED: "Failed to fetch offers for you",
  CONFIG_FETCH_FAILED: "Failed to fetch configurations",
  CUSTOMER_FETCH_FAILED: "Failed to fetch customer details",

  coapp_add_success: "Co-Applicant added.",
  coapp_add_fail: "Unable to add Co-Applicant",
  coapp_update_success: "Co-Applicant updated",
  loan_req_update_fail: "Unable to update loan requirement",
  loan_req_update_success: "Loan requirement updated",
  family_member_add_fail: "Unable to add family member",
  family_member_update_fail: "Unable to update family member details",

  protected_file: "This file is protected. Please provide password",
  password_invalid: "Password incorrect. Please try again",
  pincode_invalid: "Invalid pincode",
  reject_protected: "This file is protected. Please upload a different file",
  INVALID_PDF: "Uploaded file is not a valid PDF.",
};

export default messages;

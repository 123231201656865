import React from "react";
import "./DfsButtonLoader.css";

const DfsUiLoader = (props) => {
  return (
    <React.Fragment>
      <div class="loadingio-spinner-ellipsis-qje3l0vorsa">
        <div class="ldio-fqb5up37nb">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DfsUiLoader;

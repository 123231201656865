import axios from "axios";
import messages from "shared/messages";
import sessionStorage from "shared/sessionStorage";

export const setAuthToken = (token) => {
  if (token) {
    instance.defaults.headers.common["authorization"] = "Bearer " + token;
  }
};
export const removeAuthToken = () => {
  instance.defaults.headers.common["authorization"] = "";
};
const instance = axios.create({});
console.log("Auth axios", instance);
instance.defaults.headers.common["authorization"] =
  "Bearer " + sessionStorage.getAccessToken();

instance.interceptors.request.use(
  (request) => {

    return request;
  },
  (error) => {
    console.log("Error in requesting", error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response && response.data;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      // DFS-5623: This log triggers the refresh of token in trademate app. DO NOT REMOVE.
      console.log("session_expired");
      // window.location.hash = "/pre-auth/login";
      // window.location.reload(true);
    }

    // Show custom message if status code is 413
    if (error && error.response && error.response.status === 413) {
      return Promise.reject({
        status: {
          messageDescription: messages.FILE_SIZE_ERR,
        },
      });
    }
    // error.handleGlobally = errorComposer(error);
    return Promise.reject(
      error.response && error.response.data
        ? error.response.data
        : error.response
    );
  }
);

export default instance;

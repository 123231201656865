import axios from "axios";
import sessionStorage from "shared/sessionStorage";
import {
  decryptPayload,
  encryptPayload,
  generateRSAKeyPair,
  setAESKey,
} from "shared/utils/encryption";
import AuthAxios from "../../axios/axios";
import { devEnvironment } from "../../environments/environment.dev";
import apiConfig from "./apiConfig";
import { postForm } from "containers/partner-module/partner.http.service";
import storageService from "shared/localstorage";

let tokenHeader = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const getEncHeader = (encPublicKey) => {
  return {
    headers: {
      "X-Session-Key": encPublicKey,
    },
  };
};

const getEncryptedPayloadHeader = () => {
  return {
    headers: {
      "X-Api-Enc": true,
    },
  };
};

let fileUploadHeader = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

let fileDownloadHeader = {
  responseType: "blob",
};

const SKIP_ENC_REQUEST_TYPES = [
  apiConfig.requestTypes.TOKEN,
  apiConfig.requestTypes.LOGIN,
  apiConfig.requestTypes.REFRESH_TOKEN,
  apiConfig.requestTypes.PREAUTH_GET,
  apiConfig.requestTypes.PREAUTH_PUT,
  apiConfig.requestTypes.LOGOUT,
  apiConfig.requestTypes.FORMDATA,
  apiConfig.requestTypes.FILE_UPLOAD,
  apiConfig.requestTypes.DOWNLOAD,
  apiConfig.requestTypes.DIRECTDOWNLOAD,
  apiConfig.requestTypes.ENC_KEY,
];

export const environmentUrl =
  window.location.origin.includes("localhost") ||
  window.location.origin.includes("127.0.0.1")
    ? devEnvironment.rootUrl
    : window.location.origin + "/";
const httpService = {
  send: async (requestBody, payload = "") => {
    const reqItem = fetchApi(requestBody);
    if (!reqItem) return;
    let enableEncyption = !SKIP_ENC_REQUEST_TYPES.includes(reqItem.type);
    const hostName = document.location.hostname.split(".")[0];
    if (
      hostName === "localhost" ||
      hostName === "kyros" ||
      hostName === "stage01" ||
      hostName === "argus"
    ) {
      storageService.setIsEncyptionDisable();
      enableEncyption = !storageService.getIsEncyptionDisable();
    }
    let decryptedResponse;

    try {
      const encryptedPayload =
        enableEncyption && payload !== ""
          ? await encryptPayload(payload)
          : payload;

      let response;
      try {
        response = await makeNetworkCallFromAxios(
          reqItem,
          encryptedPayload,
          enableEncyption ? getEncryptedPayloadHeader() : null
        );
      } catch (err) {
        if (err.encryptedPayload) {
          throw await decryptPayload(err.encryptedPayload);
        } else {
          throw err;
        }
      }

      decryptedResponse =
        enableEncyption && response && response.encryptedPayload
          ? await decryptPayload(response.encryptedPayload)
          : response;
    } catch (err) {
      throw err;
    }

    return decryptedResponse;
  },
};

const fetchApi = (requestBody) => {
  let reqItem = { ...apiConfig.getApi(requestBody) };
  if (requestBody.params) {
    for (const key in requestBody.params) {
      if (reqItem.path.includes(`{${key}}`)) {
        reqItem.path = reqItem.path.replace(
          `{${key}}`,
          requestBody.params[key]
        );
      }
    }
  } else if (requestBody.body) {
    reqItem.body = { ...requestBody.body };
  }
  return reqItem;
};

const makeNetworkCallFromAxios = async (requestBody, payload, header) => {
  let clientId = sessionStorage.getClientId() || devEnvironment.client_id;
  let apiGatewayUrl =
    clientId === "apollo-client" ? "api-client/" : devEnvironment.apiUrl;

  let authUrl = environmentUrl + devEnvironment.authUrl + requestBody.path;
  let apiUrl = environmentUrl + apiGatewayUrl + requestBody.path;

  let configAxios = AuthAxios;

  if (requestBody.type === apiConfig.requestTypes.TOKEN) {
    if (window.location.origin.includes("partner.davinta.com")) {
      authUrl =
        "https://loans.davinta.com/" +
        devEnvironment.authUrl +
        requestBody.path;
    }
    console.log("make a call to fetch token");
    const params = {
      grant_type: "password",
      client_id: clientId,
      ...payload,
    };
    const body = `grant_type=${params.grant_type}&username=${escape(
      params.username
    )}&password=${escape(params.password)}&client_id=${params.client_id}`;
    const response = await axios.post(authUrl, body, tokenHeader);
    return response;
  } else if (requestBody.type === apiConfig.requestTypes.ENC_KEY) {
    const keyPair = generateRSAKeyPair();
    try {
      const header = getEncHeader(keyPair.publicKeyPem);
      const response = await axios.get(apiUrl, header);
      return await setAESKey(keyPair.privateKey, response.data);
    } catch (err) {
      throw err;
    }
  } else if (requestBody.type === apiConfig.requestTypes.GET) {
    return configAxios.get(apiUrl, header);
  } else if (requestBody.type === apiConfig.requestTypes.POST) {
    return configAxios.post(apiUrl, payload, header);
  } else if (requestBody.type === apiConfig.requestTypes.PUT) {
    return configAxios.put(apiUrl, payload, header);
  } else if (requestBody.type === apiConfig.requestTypes.PATCH) {
    return configAxios.patch(apiUrl, payload, header);
  } else if (requestBody.type === apiConfig.requestTypes.DELETE) {
    return configAxios.delete(apiUrl, header);
  } else if (requestBody.type === apiConfig.requestTypes.FORMDATA) {
    return configAxios.post(apiUrl, payload, fileUploadHeader);
  } else if (requestBody.type === apiConfig.requestTypes.FILE_UPLOAD) {
    return configAxios.post(apiUrl, payload, fileUploadHeader);
  } else if (requestBody.type === apiConfig.requestTypes.DOWNLOAD) {
    return configAxios.get(apiUrl, fileDownloadHeader);
  } else if (requestBody.type === apiConfig.requestTypes.DIRECTDOWNLOAD) {
    return apiUrl + "?access_token=" + sessionStorage.getAccessToken();
  } else if (requestBody.type === apiConfig.requestTypes.REDIRECT) {
    return postForm(apiUrl);
  }
};

export default httpService;

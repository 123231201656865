export const LOGO = {
  UGCL: "./ugrow_logo.png",
};

export const CoLendingLOGO = {
  KVBL: "./davinta_kvb.png",
  SFL: "./davinta_shriram.svg",
  TFS: "./davinta_tricolor.svg",
};

export const DavintaParagraph =
  "This credit is jointly offered by Davinta Financial Services Pvt. Ltd. &";

export const CoLendingParagraph = {
  KVBL: "The Karur Vysya Bank Ltd.",
  SFL: "Shriram Finance Ltd.",
  TFS: "Tricolor Financial Services Pvt. Ltd.",
};

export const LENDER = {
  KVB: "KVBL",
  SF: "SFL",
  DFS: "DFSL",
  TFS: "TFS",
};

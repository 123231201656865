import { getApplicationConfiguration } from "containers/partner-module/partner.http.service";
import { ConfigurationContext } from "contexts/configuration.context";
import { useCallback, useContext } from "react";
import messages from "shared/messages";
import { snackbarService } from "shared/snackbar/snackbar.service";

export const useApplicationConfig = () => {
  const { applicationConfig, setApplicationConfig } =
    useContext(ConfigurationContext);

  const getApplicationConfig = useCallback(async () => {
    try {
      const applicationConfiguration = await getApplicationConfiguration();
      setApplicationConfig(applicationConfiguration);
      return applicationConfiguration;
    } catch (err) {
      snackbarService.show(
        snackbarService.messageType.error,
        err,
        messages.CONFIG_FETCH_FAILED
      );
    }
  }, [setApplicationConfig]);

  return {
    applicationConfig,
    getApplicationConfig,
  };
};

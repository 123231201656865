export const UI_CONFIG = {
  leaveConfirmationPop: {
    key: "leaveConfirmationPop",
    // disableEscapeKeyDown: true,
    // disableBackdropClick: true,
    title: "Do you want to leave this page?",
    label:
      "Any unsaved changes will be lost if you leave the page without submitting.",
    buttons: [
      {
        title: "No",
        keyName: "cancel",
        // fullWidth: true,
        type: "outlined",
        isLoader: false,
      },
      {
        title: "Yes, leave",
        keyName: "confirm",
        // fullWidth: true,
        isLoader: false,
      },
    ],
  },
  submitConfirmationPop: {
    key: "submitConfirmationPop",
    title: "Attention!",
    label:
      "Do you agree that the details filled by you are correct? You won’t be able to edit the application once you proceed.",
    buttons: [
      {
        title: "No, I want to change",
        keyName: "cancel",
        type: "outlined",
        isLoader: false,
      },
      {
        title: "Yes, I agree",
        keyName: "confirm",
        isLoader: false,
      },
    ],
  },
  exitConfirmationPop: {
    key: "exitConfirmationPop",
    // disableEscapeKeyDown: true,
    // disableBackdropClick: true,
    title: "Are you sure you want to exit?",
    label: "We will not be able to provide our services without your consent.",
    buttons: [
      {
        title: "Yes, exit",
        keyName: "confirm",
        type: "outlined",
        // fullWidth: true,
        isLoader: false,
      },
      {
        title: "No, go back",
        keyName: "cancel",
        // fullWidth: true,
        isLoader: false,
      },
    ],
  },
  vkycRejectedPopup: {
    key: "vkycRejectedPopup",
    title: "Are you sure?",
    label: "Attention! Your VKYC has been rejected. This could impact your Limit usage. Please contact a Davinta executive to complete your KYC process.",
    buttons: [
      {
        title: "Cancel",
        keyName: "cancel",
        type: "outlined",
        isLoader: false,
      },
      {
        title: "Yes, skip",
        keyName: "confirm",
        isLoader: false,
      },
    ],
  },
  skipVkycAndAutopayConfirmationPop: {
    key: "skipVkycAndAutopayConfirmationPop",
    title: "Are you sure?",
    label: "Attention! Usage limits might be restricted as VKYC/Autopay remains incomplete. You have the option to complete these at a later time.",
    buttons: [
      {
        title: "Cancel",
        keyName: "cancel",
        type: "outlined",
        isLoader: false,
      },
      {
        title: "Yes, skip",
        keyName: "confirm",
        isLoader: false,
      },
    ],
  },
};

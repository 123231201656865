import React from "react";
import ReactDOM from "react-dom";
import classes from "./customModal.module.css";

const CustomModal = (props) => {
  return props.isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className={classes["modal-overlay"]} />
          <div
            className={classes["modal-wrapper"]}
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className={classes["modal"] + " py-3.5 px-4"}>
              <div className={classes["modal-header"]}>
                {props.hideHeader ? null : (
                  <div className="flex items-center mx-1">
                    <h1>{props.header}</h1>
                  </div>
                )}
                {props.hideClose ? null : (
                  <button
                    id="modal-close-button"
                    type="button"
                    className={classes["modal-close-button"]}
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={props.hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                )}
              </div>
              {props.children}
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default CustomModal;
